import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '../helpers/Alert';
import Grid from '../Grid';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
/**
 * display's the payment configuration
 * belong to each company
 * @returns JSX
 */
const ShowPaymentConfig = () => {
  const [gridData, setGridData] = useState([]);
  const GATEWAY = ['Nexi', 'Stripe', 'Axerve'];
  const history = useHistory();

  useEffect(() => {
    getPaymentConfig();
    // eslint-disable-next-line
    }, []);
  /**
   * get the list of payment configuration
   */
  const getPaymentConfig = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`/api/gateway/payment-config`, { headers: { authorization: token } });
      setGridData(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };
  /**
   * confirmation box to remove payment configuration
   * @param {number|string} payment_config_id
   */
  const confirmDrop = (payment_config_id) => {
    console.log('confirmDrop -> payment_config_id', payment_config_id);
    confirmAlert({
      title: 'Remove Record',
      message: 'Are you sure to remove this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              await axios.delete(`/api/gateway/delete-payment-config/${payment_config_id}`, { headers: { authorization: token } });
              await getPaymentConfig();
            } catch (err) {
              const { data } = err.response;
              Alert('error', 'Server Error', data);
            }
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };
  // column definitions
  const columnDefs = [
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => history.push('/update-payment-config-list', data)}>
            <i className='fas fa-pencil-alt text-success' title='Edit entry'></i>
          </a>
        );
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => confirmDrop(data.payment_config_id)}>
            <i className='far fa-trash-alt text-danger' title='Delete entry'></i>
          </a>
        );
      }
    },
    { field: 'payment_config_id', hide: true },
    { headerName: 'Company ID', field: 'company_id', filter: true },
    { headerName: 'API Key', field: 'api_key', filter: true },
    { headerName: 'Secret Key', field: 'secret_key', filter: true },
    { headerName: 'Group Code', field: 'group_code', filter: true },
    {
      headerName: 'Gateway ID',
      filter: true,
      cellRendererFramework: ({ data }) => {
        const gatewayID = data.gateway_id;
        /*eslint-disable-next-line*/
                return GATEWAY[gatewayID - 1];
      }
    }
  ];

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Payment Config</h4>
              <Grid columnDefs={columnDefs} rowData={gridData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowPaymentConfig;
