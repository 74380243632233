import React, { useState } from 'react';
import axios from 'axios';
import Alert from '../helpers/Alert';
import useForm from 'react-hook-form';
/**
 * creates alias for the provider who does not
 * provide Alias API
 * @param {any} props
 * @returns JSX
 */
const CreateGeneralAlias = (props) => {
  const { register, errors, setValue, getValues, handleSubmit } = useForm();
  const [formValues, setFormValues] = useState({});
  /**
   * generic function to handle input
   * @param {string} control
   * @param {any} value
   */
  const handleControl = (control, value) => {
    setValue(control, value);
    setFormValues({ ...formValues, ...getValues() });
  };
  /**
   * handles submit
   */
  const handleFormSubmit = async () => {
    try {
      const { provider_id } = props;
      const token = localStorage.getItem('token');
      formValues.provider_id = provider_id;
      await axios.post('/api/provider/create-alias-general', { formValues }, { headers: { authorization: token } });
      Alert('success', 'Alias Created', 'Alias created successfully');
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <h4 className='card-title'>Create Alias</h4>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>Alias</label>
            <input
              name='alias'
              value={formValues['alias'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('alias', e.target.value)}
              ref={register({ name: 'alias' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors.alias && errors.alias.message}</p>
          </div>
        </div>
        <button type='submit' className='btn btn-primary float-right'>
          Create
        </button>
      </form>
    </>
  );
};

export default CreateGeneralAlias;
