import React from 'react';
import { format } from 'date-fns';
import { converDateToEuroZone, formatDate } from '../helpers/utils';

const rowClassRules = {
  'ag-row-idle-color': (params) => {
    return params.data.status === 0;
  },
  'ag-row-done-color': (params) => {
    return params.data.status === 1;
  },
  'ag-row-running-color': (params) => {
    return params.data.status === 2;
  },
  'ag-row-batch-color': (params) => {
    return params.data.status === 3;
  }
};
/**
 * child component returns list of columns
 * @param  {function} startConfirm
 * @param  {function} confirmDrop
 * @param  {function} setCampaginDetails
 */
const MailViewColumns = (startConfirm, confirmDrop, setCampaginDetails) => {
  const MODE = { 0: 'Real-time', 1: 'Batch' };
  const TYPE = ['Visual', 'Simple', 'Template'];

  return [
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return data.campaign_type === 2 ? (
          <a
            href='# '
            onClick={data.status === 3 ? () => startConfirm(data.campaign_id, data.mode) : null}
            role='button'
            aria-disabled='true'
          >
            <i className={data.status === 3 ? 'fas fa-play text-success' : 'fas fa-play text-secondary'} title='Start SMS'></i>
          </a>
        ) : null;
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => confirmDrop(data.campaign_id)}>
            <i className='far fa-trash-alt text-danger' title='Delete entry'></i>
          </a>
        );
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => setCampaginDetails(data)} data-toggle='modal' data-target='#columnDetail'>
            <i className='fas fa-info-circle text-primary' title='information'></i>
          </a>
        );
      }
    },
    { field: 'campaign_type', hide: true },
    { headerName: 'Campaign ID', field: 'campaign_id' },
    { headerName: 'Company Name', field: 'company_name', filter: true },
    { headerName: 'Campaign Name', field: 'campaign_name', filter: true },
    { headerName: 'Provider Name', field: 'mail_provider_name', filter: true },
    { headerName: 'Sender', field: 'sender', filter: true },
    {
      headerName: 'Mode',
      field: 'mode',
      filter: true,
      cellRendererFramework: ({ value }) => {
        return MODE[value];
      }
    },
    {
      headerName: 'Schedule Time',
      field: 'schedule_time',
      filter: 'agDateColumnFilter',
      hide: false,
      cellRendererFramework: ({ value }) => {
        return value ? formatDate(converDateToEuroZone(value)) : 'N/A';
      }
    },
    { headerName: 'Duration', field: 'duration', filter: true },
    { headerName: 'SMS URL', field: 'sms_url', filter: true },
    { headerName: 'Mail Template', field: 'template_name', filter: true },
    {
      headerName: 'Created Date',
      field: 'created_at',
      filter: 'agDateColumnFilter',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },
    {
      headerName: 'Updated Date',
      field: 'updated_at',
      filter: 'agDateColumnFilter',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },
    { headerName: null, field: 'company_id', hide: true },
    { headerName: null, field: 'campaign_id', hide: true }
  ];
};

export { MailViewColumns, rowClassRules };
