import React, { useState } from 'react';
import MessageView from './MessageView';
import MailView from './MailView';
/**
 * its an wrapper (parent) component it renders SMS campaigns view and
 * mail campaigns view as child components
 */
const CampaignList = () => {
  const [campaginView, setCampaginView] = useState(true);
  const handleCampaignView = () => {
    setCampaginView(!campaginView);
  };
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>Campaign List</h4>
            <div className='form-check form-check-inline d-flex'>
              <div className='form-check form-check-inline'>
                <label className='form-check-label mr-4'>
                  <input
                    className='form-check-input'
                    checked={campaginView}
                    onChange={handleCampaignView}
                    type='radio'
                    name='campaginView'
                  />
                  View SMS
                </label>
                <label className='form-check-label'>
                  <input
                    className='form-check-input'
                    checked={!campaginView}
                    onChange={handleCampaignView}
                    type='radio'
                    name='campaginView'
                  />
                  View Mail
                </label>
              </div>
            </div>
            {campaginView ? <MessageView /> : <MailView />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignList;
