import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import Alert from '../helpers/Alert';
import _ from 'lodash';
import CodeEditor from '../controls/CodeEditor';
import { useLocation, useHistory } from 'react-router-dom';
/**
 * Creates mail template using HTML editor
 * @returns JSX
 */
const CreateMailTemplate = () => {
  const [formValues, setFormValues] = useState();
  const [templateList, settemplateList] = useState([]);
  const { register, errors, setValue, handleSubmit } = useForm();
  const [companyParams, setCompanyParams] = useState({});

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const companyParams = { ...location.state };
      setCompanyParams(companyParams);
    } else {
      Alert('error', 'Internal Error', "Could't find associated company.");
      history.push('/company-list');
    }
    // eslint-disable-next-line
    }, [])
  /** generic handler for inputs
   * @param  {string} control
   * @param  {any} value
   */
  const handleControl = (formControl, value) => {
    setFormValues({ ...formValues, [formControl]: value });
    setValue(formControl, value);
  };
  /**
   * adds the new template to the list
   */
  const handleAddTemplate = () => {
    let template = [];
    template = [...templateList];
    if (_.has(formValues, 'template_name') && _.has(formValues, 'template_html')) {
      const tagRegEx = /<[^>]*>/g;
      const template_name = formValues.template_name;
      const template_html = formValues.template_html;
      const isContentExist = _.isEmpty(template_html.replace(tagRegEx, '').trim());
      const isExistTemplate = template.some((k) => k.template_name === template_name);

      if (template_name && !isContentExist) {
        if (!isExistTemplate) {
          template.push({ template_name, template_html });
          settemplateList(template);
        } else {
          Alert('error', 'Name Exists', 'Template name already exists.', 'fas fa-exclamation');
        }
      } else {
        Alert('error', 'Empty Fields', 'Template fields cannot be empty.', 'fas fa-exclamation');
      }
    } else {
      Alert('error', 'Empty Fields', 'Template fields cannot be empty.', 'fas fa-exclamation');
    }
  };
  /**
   * removes the template from the list
   * @param  {event} e
   * @param  {number} index
   */
  const handleRemoveTemplate = (e, index) => {
    let templates = templateList.filter((_, i) => i !== index);
    settemplateList(templates);
    e.preventDefault();
  };
  /**
   * submits form data
   */
  const handleFormSubmit = async () => {
    try {
      //delete data['batchfiles'];
      const token = localStorage.getItem('token');
      const { company_id } = companyParams;
      await axios.post('/api/mailtemplate/create-template', { company_id, templateList }, { headers: { authorization: token } });
      Alert('success', 'success', 'Template created successfully', 'fa-check-circle');
    } catch (err) {
      Alert('error', 'Error Occured !!', 'Error occured while inserting record.', 'fas fa-exclamation');
      console.log(err);
    }
  };

  console.log(formValues);

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>Create Mail Template @{companyParams.company_name}</h4>
            <hr />
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className='form-group'>
                <div className='col-md-9'>
                  <label className='mr-3'>Template Name</label>
                  <input
                    name='template_name'
                    className='form-control'
                    onChange={(e) => handleControl('template_name', e.target.value)}
                    value={(formValues && formValues['template_name']) || ''}
                    ref={register({ name: 'template_name' }, { required: { value: true, message: 'Field is required.' } })}
                  />
                  <p className='error-text'>{errors.template_name && errors.template_name.message}</p>
                </div>
              </div>

              <div className='form-group'>
                <div className='col-md-12'>
                  <label className='mr-3'>Template HTML</label>
                  <CodeEditor handleControl={handleControl} formValues={formValues} register={register} errors={errors} />
                  <button type='button' className='btn btn-info btn-sm' onClick={() => handleAddTemplate()}>
                    Add Template
                  </button>
                </div>
              </div>
              <div className='form-group'>
                <div className='col-md-12'>
                  <ul className='list-group'>
                    {_.isEmpty(templateList) ? (
                      <li className='list-group-item'>No record</li>
                    ) : (
                      templateList.map(({ template_name }, i) => (
                        <li key={i} className='d-flex list-group-item justify-content-between'>
                          <p className='m-0'>
                            <span className='h5 mr-2'>
                              <i className='fas fa-file-code'></i>
                            </span>{' '}
                            {template_name}
                          </p>
                          <a className='btn btn-sm btn-link m-0 text-danger' href='/#' onClick={(e) => handleRemoveTemplate(e, i)}>
                            <i className='fas fa-times'></i>
                          </a>
                        </li>
                      ))
                    )}
                  </ul>
                  <button type='button' onClick={() => handleFormSubmit()} className='btn btn-primary mt-3 float-right'>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMailTemplate;
