import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Alert from '../helpers/Alert';
import Grid from '../../components/Grid';
import _ from 'lodash';
import { format } from 'date-fns';
import csv from 'csvtojson';
import useForm from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert';
import 'react-datepicker/dist/react-datepicker.css';
/**
 * Uploads files to the campaign,
 * it accept two types of files
 * JSON and CSV, coverts them into
 * JSON data response and send to
 * the server
 * @param {any} props
 * @returns JSX
 */
const BatchFile = (props) => {
  // using useForm for validation
  const { register, errors, setValue, handleSubmit } = useForm();
  const [formValues, setFormValues] = useState({});
  const [options, setOptions] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [campaginView, setCampaginView] = useState(true);
  const dataRef = useRef();

  dataRef.current = fileData;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getCampaigns();
    return () => {
      setOptions([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaginView]);
  /**
   * sets the type of campaignView
   */
  const handleCampaignView = () => {
    setCampaginView(!campaginView);
  };
  /**
   * gets the list of campaigns
   *
   */
  const getCampaigns = async () => {
    try {
      const token = localStorage.getItem('token');
      const endpoint = campaginView ? 'get-file-campaigns-by-message' : 'get-file-campaigns-by-mail';
      const campaigns = await axios.get(`/api/campaign/${endpoint}`, { headers: { authorization: token } });
      // eslint-disable-next-line array-callback-return
      if (_.isObject(campaigns)) {
        // eslint-disable-next-line array-callback-return
        campaigns.data.map(({ campaign_id, campaign_name }) => {
          setOptions((options) => [...options, { label: campaign_name, value: campaign_id }]);
        });
      } else if (campaigns === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * mapped fields of contact table
   */

  const FIELDS = [
    'contact_name',
    'mobile_number',
    'data1',
    'data2',
    'data3',
    'data4',
    'data5',
    'data6',
    'data7',
    'data8',
    'data9',
    'data10',
    'data11',
    'data12',
    'data13',
    'data14',
    'data15',
    'data16',
    'data17',
    'data18',
    'secure'
  ];

  /**
   * default options to be used by the
   * aggrid.
   */
  const gridOptions = {
    defaultColDef: {
      // editable: true,
    },
    columnDefs: [
      { headerName: 'ID', field: 'id' },
      { headerName: 'Contact Name', field: 'contact_name' },
      {
        headerName: 'Mobile Number',
        field: 'mobile_number',
        cellRendererFramework: ({ value }) => {
          if (value.length < 11) {
            return '+39' + value;
          }
          return value;
        }
      },
      { headerName: 'Data1', field: 'data1' },
      { headerName: 'Data2', field: 'data2' },
      { headerName: 'Data3', field: 'data3' },
      { headerName: 'Data4', field: 'data4' },
      { headerName: 'Data5', field: 'data5' },
      { headerName: 'Data6', field: 'data6' },
      { headerName: 'Data7', field: 'data7' },
      { headerName: 'Data8', field: 'data8' },
      { headerName: 'Data9', field: 'data9' },
      { headerName: 'Data11', field: 'data11' },
      { headerName: 'Data12', field: 'data12' },
      { headerName: 'Data13', field: 'data13' },
      { headerName: 'Data14', field: 'data14' },
      { headerName: 'Data15', field: 'data15' },
      { headerName: 'Data16', field: 'data16' },
      { headerName: 'Data17', field: 'data17' },
      { headerName: 'Data18', field: 'data18' },
      { headerName: 'Secure', field: 'secure' },
      {
        headerName: null,
        cellRendererFramework: ({ data }) => {
          return (
            <a href='# ' onClick={() => confirmDrop(data.id)}>
              <i className='far fa-trash-alt text-danger' title='Delete entry'></i>
            </a>
          );
        }
      }
    ]
  };
  /**
   * removes record the record from
   * the batch fle grid view
   * @param  {number|string} recordID - record id
   */
  const confirmDrop = (recordID) => {
    confirmAlert({
      title: 'Remove Record',
      message: 'Are you sure to remove this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const filteredData = dataRef.current.filter((o) => {
              return o.id !== recordID;
            });
            setFileData(filteredData);
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  /**
   * generic function to handle all controls
   * and update its state accordingly.
   *
   * @param  {string} formControl - input control name
   * @param  {any} value - input value set by user
   */
  const handleControl = (formControl, value) => {
    setFormValues({ ...formValues, [formControl]: value });
    setValue(formControl, value);
  };

  /**
   * read the file and upload it to the
   * aggrid
   * @param  {string} control - batchfile control
   * @param  {event} e - event from the eventhandler
   */
  const handleFileUpload = (control, e) => {
    const file = e.target.files[0].name;
    const reader = new FileReader();
    if (file.includes('.csv')) {
      reader.onload = onCSVReaderLoad;
      reader.readAsText(e.target.files[0]);
    } else {
      reader.onload = onJSONReaderLoad;
      reader.readAsText(e.target.files[0]);
    }

    setValue(control, file);
  };
  /**
   * reads JSON format of uploaded file
   * @param  {event} e
   */
  const onJSONReaderLoad = (e) => {
    const prasedData = JSON.parse(e.target.result);
    const rowData = [];
    prasedData.map((aObject, idx) => {
      let index = 0;
      const row = {};
      row['id'] = idx;
      _.mapValues(aObject, (value) => {
        row[FIELDS[index]] = value;
        ++index;
      });

      rowData.push(row);
      return true;
    });

    setFileData(rowData);
  };
  /**
   * reads CSV uploaded file
   * @param  {event} e
   */
  const onCSVReaderLoad = (e) => {
    const obj = e.target.result;
    const rowData = [];
    csv()
      .fromString(obj)
      .then((aCollection) => {
        aCollection.map((aObject, idx) => {
          let index = 0;
          const row = {};
          row['id'] = idx;
          _.mapValues(aObject, (value) => {
            row[FIELDS[index]] = value;
            ++index;
          });
          rowData.push(row);
          return true;
        });
        setFileData(rowData);
      });
  };
  /**
   * handles form submit
   * @param  {object} data
   */
  const handleFormSubmit = async (data) => {
    try {
      fileData.map((o) => delete o['id']);
      data['batchfile'] = fileData;
      data['created_at'] = format(Date.now(), 'yyyy-MM-dd hh:mm:ss');
      const token = localStorage.getItem('token');
      const endpoint = campaginView ? '/api/contact/add-contact' : '/api/contact/add-mail-contact';
      const res = await axios.post(endpoint, { data }, { headers: { 'Content-Type': 'application/json', authorization: token } });
      if (res === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
        Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
      } else {
        Alert('success', 'success', 'Batch contact uploaded.', 'fa-check-circle');
      }
    } catch (err) {
      console.log(err);
      Alert('error', 'Error', 'An error occured while inserting record.', 'fa-exclamation');
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Upload Contact File</h4>
              <hr />
              <div className='form-check form-check-inline'>
                <label className='form-check-label mr-4'>
                  <input
                    className='form-check-input'
                    checked={campaginView}
                    onChange={handleCampaignView}
                    type='radio'
                    name='campaginView'
                  />
                  View SMS
                </label>
                <label className='form-check-label'>
                  <input
                    className='form-check-input'
                    checked={!campaginView}
                    onChange={handleCampaignView}
                    type='radio'
                    name='campaginView'
                  />
                  View Mail
                </label>
              </div>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className='form-group'>
                  <label className='mr-3'>Campaign</label>
                  <select
                    className='form-control'
                    name='campaign_id'
                    onChange={(e) => handleControl('campaign_id', e.target.value)}
                    options={options}
                    ref={register({ name: 'campaign_id' }, { required: { value: true, message: 'Field is required.' } })}
                  >
                    <option value=''>Select Campaign</option>
                    {options &&
                      options.map(({ label, value }, index) => {
                        return (
                          <option value={value} key={index}>
                            {label}
                          </option>
                        );
                      })}
                  </select>
                  <p className='error-text'>{errors.campaign_id && errors.campaign_id.message}</p>
                  <input
                    className='form-control'
                    name='batchfile'
                    label='Batch file'
                    type='file'
                    accept='.csv,.json'
                    onChange={(e) => handleFileUpload('batchfile', e)}
                    ref={register({ name: 'batchfile' }, { required: { value: true, message: 'Field is required.' } })}
                  />
                  <p className='error-text'>{errors.batchfile && errors.batchfile.message}</p>
                  <h6 className='mt-3'>Review Data</h6>
                  <Grid gridOptions={gridOptions} rowData={fileData} />
                </div>
                <button className='btn btn-primary float-right' disabled={_.isEmpty(fileData) ? true : false}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BatchFile;
