import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
/**
 * Main is wrapper around Header and Sidebar
 * main is like a masterpage component
 * @param {any} props
 * @returns JSX
 */
const Main = (props) => {
  return (
    <div id='main-wrapper' data-sidebartype='mini-sidebar'>
      <Header />
      <Sidebar />
      <div className='page-wrapper'>{props.children}</div>
    </div>
  );
};

export default Main;
