import cheers from 'cheers-alert';
import 'cheers-alert/src/cheers-alert.css'; //load style
import 'font-awesome/css/font-awesome.min.css'; //load font-awesome
/**
 * Alert component gives warning,errors or success
 * on API calls.
 * @param {string} type
 * @param {string} title
 * @param {string} message
 * @param {string} icon
 * @returns object
 */
const Alert = (type, title, message, icon) => {
  return cheers[type]({
    title: title,
    message: message,
    alert: 'slideleft',
    icon: icon, //'fa-check-circle',
    duration: 10,
    callback: () => {}
  });
};

export default Alert;
