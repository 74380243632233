import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import _ from 'lodash';
import axios from 'axios';
import { format } from 'date-fns';
import Alert from '../helpers/Alert';
import { useLocation } from 'react-router-dom';

const UpdateUser = () => {
  const location = useLocation();
  const [formValues, setFormValues] = useState({});
  const { register, errors, setValue, watch, handleSubmit } = useForm();

  useEffect(() => {
    if (location.state) {
      console.log('TCL: location.state', location.state);
      setFormValues({ ...formValues, ...location.state });
      _.mapValues(location.state, (value, key) => {
        setValue(key, value);
      });
    }
    /*eslint-disable-next-line*/
    }, [])


  const handleControl = (formControl, value) => {
    console.log('TCL: handleControl -> formControl, value', formControl, value);
    setFormValues({ ...formValues, [formControl]: value });
    setValue(formControl, value);
  };

  const handleFormSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      delete formValues['created_at'];
      delete formValues['repassword'];
      formValues['updated_at'] = format(Date.now(), 'yyyy-MM-dd HH:mm:ss');
      const res = await axios.put('/api/user/update-user', formValues, { headers: { authorization: token } });
      if (res === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
        Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
      } else {
        Alert('success', 'Operation Successfull', `User successfull created.`, 'fa-check-circle');
      }
    } catch (err) {
      console.log(err);
      Alert('error', 'Error Occured !!', 'Error occured while creating user.', 'fas fa-exclamation');
    }
  };

  console.log('xxx', formValues);

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>Update User</h4>
            <hr />
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className='form-group'>
                <label className='mr-3'>Role Type</label>
                <select
                  name='role'
                  className='form-control  form-control-lg'
                  value={formValues && formValues['role'] && formValues['role'].toString()}
                  onChange={(e) => handleControl('role', e.target.value)}
                  ref={register({ name: 'role' }, { required: { value: true, message: 'Field is required.' } })}
                >
                  <option value=''>Select type</option>
                  <option value='0'>Admin</option>
                  <option value='1'>Client</option>
                </select>
                <p className='error-text'>{errors.role && errors.role.message}</p>
                <label className='mr-3'>Username</label>
                <input
                  className='form-control  form-control-lg'
                  name='username'
                  disabled
                  onChange={(e) => handleControl('username', e.target.value)}
                  type='text'
                  value={formValues['username'] || ''}
                  ref={register({ name: 'username' }, { required: { value: true, message: 'Field is required.' } })}
                />
                <p className='error-text'>{errors.username && errors.username.message}</p>
                <label className='mr-3'>Active</label>
                <select
                  name='active'
                  className='form-control  form-control-lg'
                  value={formValues['active'] || ''}
                  onChange={(e) => handleControl('active', e.target.value)}
                  ref={register({ name: 'active' }, { required: { value: true, message: 'Field is required.' } })}
                >
                  <option value=''>Select type</option>
                  <option value='0'>In-active</option>
                  <option value='1'>Active</option>
                </select>
                <p className='error-text'>{errors.active && errors.active.message}</p>
                <label className='mr-3'>New Password</label>
                <input
                  className='form-control  form-control-lg'
                  autoComplete='new-password'
                  name='password'
                  onChange={(e) => handleControl('password', e.target.value)}
                  type='password'
                  value={formValues['password'] || ''}
                  ref={register(
                    { name: 'password' },
                    {
                      required: {
                        value: true,
                        message: 'Field is required.'
                      },
                      minLength: { value: 8, message: 'minimum length must be 8' }
                    }
                  )}
                />
                <p className='error-text'>{errors.password && errors.password.message}</p>
                <label className='mr-3'>Re-Password</label>
                <input
                  className='form-control  form-control-lg'
                  name='repassword'
                  onChange={(e) => handleControl('repassword', e.target.value)}
                  type='password'
                  value={formValues['repassword'] || ''}
                  ref={register(
                    { name: 'repassword' },
                    {
                      required: { value: true, message: 'Field is required.' },
                      minLength: { value: 8, message: 'minimum length must be 8' },
                      validate: {
                        value: (value) => {
                          return value === watch('password') ? true : 'Password not matched.';
                        }
                      }
                    }
                  )}
                />
                <p className='error-text'>{errors.repassword && errors.repassword.message}</p>
              </div>
              <button className='btn btn-primary float-right'>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
