import React, { useState } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import Alert from '../helpers/Alert';
/**
 * creates skebby alias through Skebby Alias API
 * @param {any} props
 * @returns JSX
 */
const CreateSkebbyAlias = (props) => {
  const { provider_id } = props;
  const { register, errors, setValue, getValues, handleSubmit } = useForm();
  const [formValues, setFormValues] = useState({});
  /**
   * generic function to handle input
   * @param {string} control
   * @param {any} value
   */
  const handleControl = (control, value) => {
    setValue(control, value);
    setFormValues({ ...formValues, ...getValues() });
  };
  /**
   * handle form submit
   */
  const handleFormSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      formValues['contact-type'] = 'web';
      await axios.post('/api/provider/create-alias', { formValues, provider_id }, { headers: { authorization: token } });
      Alert('success', 'Alias Created', 'Alias created successfully');
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <h4 className='card-title'>Create Alias</h4>
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete='new-password'>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>Alias</label>
            <input
              name='alias'
              value={formValues['alias'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('alias', e.target.value)}
              ref={register({ name: 'alias' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors.alias && errors.alias.message}</p>
          </div>
        </div>

        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>Company Name</label>
            <input
              name='company-name'
              value={formValues['company-name'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('company-name', e.target.value)}
              ref={register({ name: 'company-name' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors['company-name'] && errors['company-name'].message}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>First Name (company manager)</label>
            <input
              name='contact-name'
              value={formValues['contact-name'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('contact-name', e.target.value)}
              ref={register({ name: 'contact-name' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors['contact-name'] && errors['contact-name'].message}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>Last Name (company manager)</label>
            <input
              name='contact-surname'
              value={formValues['contact-surname'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('contact-surname', e.target.value)}
              ref={register({ name: 'contact-surname' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors['contact-surname'] && errors['contact-surname'].message}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>Cod-Fiscale</label>
            <input
              name='cod-fiscale'
              type='number'
              value={formValues['cod-fiscale'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('cod-fiscale', e.target.value)}
              ref={register({ name: 'cod-fiscale' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors['cod-fiscale'] && errors['cod-fiscale'].message}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>VAT-Number</label>
            <input
              name='vat-number'
              type='number'
              value={formValues['vat-number'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('vat-number', e.target.value)}
              ref={register({ name: 'vat-number' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors['vat-number'] && errors['vat-number'].message}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>Company Addresss</label>
            <input
              name='contact-address'
              value={formValues['contact-address'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('contact-address', e.target.value)}
              ref={register({ name: 'contact-address' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors['contact-address'] && errors['contact-address'].message}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>City</label>
            <input
              name='contact-city'
              value={formValues['contact-city'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('contact-city', e.target.value)}
              ref={register({ name: 'contact-city' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors['contact-city'] && errors['contact-city'].message}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>Postal Code</label>
            <input
              name='contact-pcode'
              value={formValues['contact-pcode'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('contact-pcode', e.target.value)}
              ref={register({ name: 'contact-pcode' }, { required: { value: true, message: 'Field is required.' } })}
            ></input>
            <p className='error-text'>{errors['contact-pcode'] && errors['contact-pcode'].message}</p>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-md-9'>
            <label className='mr-3'>Website</label>
            <input
              name='contact-info'
              value={formValues['contact-info'] || ''}
              className='form-control  form-control-lg'
              onChange={(e) => handleControl('contact-info', e.target.value)}
              ref={register(
                { name: 'contact-info' },
                {
                  required: { value: true, message: 'Field is required.' },
                  pattern: {
                    value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
                    message: 'Incorrect URL it should be e.g: http://wwww.google.com'
                  }
                }
              )}
            ></input>
            <p className='error-text'>{errors['contact-info'] && errors['contact-info'].message}</p>
          </div>
        </div>

        <button type='submit' className='btn btn-primary float-right'>
          Create
        </button>
      </form>
    </>
  );
};

export default CreateSkebbyAlias;
