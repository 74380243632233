import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import Alert from '../helpers/Alert';
import _ from 'lodash';
import CodeEditor from '../controls/CodeEditor';
import { useLocation, useHistory } from 'react-router-dom';
/**
 * updates the mail template similar to createTemplate visually
 * but it updates instead
 * @returns JSX
 */
const UpdateMailTemplate = () => {
  const [formValues, setFormValues] = useState();
  const { register, errors, setValue, handleSubmit } = useForm();
  const [companyParams, setCompanyParams] = useState({});
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const companyParams = { ...location.state };
      const { mail_template_id, template_name, template_html } = location.state;
      // it fetches the data from api and restore the form
      const restoreFileds = { mail_template_id, template_name, template_html };
      setFormValues({ ...formValues, ...restoreFileds });
      setCompanyParams(companyParams);
      _.mapValues(restoreFileds, (value, key) => {
        setValue(key, value);
      });
    } else {
      Alert('error', 'Internal Error', "Could't find associated company.");
      history.push('/company-list');
    }
    // eslint-disable-next-line
    }, [])
  /**
   * generic function to handle inputs
   * @param {string} formControl
   * @param {any} value
   */
  const handleControl = (formControl, value) => {
    setFormValues({ ...formValues, [formControl]: value });
    setValue(formControl, value);
  };
  /**
   * handle the form submit
   */
  const handleFormSubmit = async () => {
    try {
      //delete data['batchfiles'];
      const token = localStorage.getItem('token');
      const { mail_template_id } = companyParams;
      await axios.post('/api/mailtemplate/update-template', { mail_template_id, ...formValues }, { headers: { authorization: token } });
      Alert('success', 'success', 'Template created successfully', 'fa-check-circle');
    } catch (err) {
      Alert('error', 'Error Occured !!', 'Error occured while inserting record.', 'fas fa-exclamation');
      console.log(err);
    }
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>Update Mail Template @{companyParams.company_name}</h4>
            <hr />
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className='form-group'>
                <div className='col-md-9'>
                  <label className='mr-3'>Template Name</label>
                  <input
                    name='template_name'
                    className='form-control'
                    onChange={(e) => handleControl('template_name', e.target.value)}
                    value={(formValues && formValues['template_name']) || ''}
                    ref={register({ name: 'template_name' }, { required: { value: true, message: 'Field is required.' } })}
                  />
                  <p className='error-text'>{errors.template_name && errors.template_name.message}</p>
                </div>
              </div>
              <CodeEditor handleControl={handleControl} formValues={formValues} register={register} errors={errors} />

              <button type='button' onClick={() => handleFormSubmit()} className='btn btn-primary mt-3 float-right'>
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateMailTemplate;
