import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import Alert from '../helpers/Alert';
/**
 * register new  account for SMS providers
 * @returns JSX
 */
const CreateProviders = () => {
  const { register, errors, setValue, getValues, handleSubmit, watch } = useForm();
  const [formValues, setFormValues] = useState({});
  const [providerTypesOptions, setProviderTypesOptions] = useState([]);

  useEffect(() => {
    getProviderTypes();
  }, []);

  /**
   * gets the available types of providers
   */
  const getProviderTypes = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/provider/provider-types', { headers: { authorization: token } });
      setProviderTypesOptions(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };
  /**
   * generic function to handle input
   * @param {string} control
   * @param {any} value
   */
  const handleControl = (control, value) => {
    setValue(control, value);
    setFormValues({ ...formValues, ...getValues() });
  };

  /**
   * form submit
   */
  const handleFormSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      delete formValues.repassword;
      await axios.post('/api/provider/create-provider', formValues, { headers: { authorization: token } });
      Alert('success', 'Provider Created', 'Provider created successfully');
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Create Connection</h4>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Provider Type</label>
                    <select
                      name='provider_type_id'
                      value={formValues['provider_type_id'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('provider_type_id', e.target.value)}
                      ref={register({ name: 'provider_type_id' }, { required: { value: true, message: 'Field is required.' } })}
                    >
                      <option value=''>Select Type</option>
                      {providerTypesOptions.map(({ provider_type_id, provider_name }, idx) => {
                        return (
                          <option key={idx} value={provider_type_id}>
                            {provider_name}
                          </option>
                        );
                      })}
                    </select>
                    <p className='error-text'>{errors.provider_type_id && errors.provider_type_id.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Provider Name</label>
                    <input
                      name='provider_name'
                      value={formValues['provider_name'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('provider_name', e.target.value)}
                      ref={register({ name: 'provider_name' }, { required: { value: true, message: 'Field is required.' } })}
                    ></input>
                    <p className='error-text'>{errors.provider_name && errors.provider_name.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Username / SSID</label>
                    <input
                      name='username'
                      value={formValues['username'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('username', e.target.value)}
                      ref={register({ name: 'username' }, { required: { value: true, message: 'Field is required.' } })}
                    ></input>
                    <p className='error-text'>{errors.username && errors.username.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Password / APIKey / AuthToken</label>
                    <input
                      name='password'
                      type='password'
                      autoComplete='new-password'
                      value={formValues['password'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('password', e.target.value)}
                      ref={register({ name: 'password' }, { required: { value: true, message: 'Field is required.' } })}
                    ></input>
                    <p className='error-text'>{errors.password && errors.password.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Confirm Password</label>
                    <input
                      name='repassword'
                      type='password'
                      autoComplete='new-password'
                      value={formValues['repassword'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('repassword', e.target.value)}
                      ref={register(
                        { name: 'repassword' },
                        {
                          required: { value: true, message: 'Field is required.' },
                          validate: {
                            value: (value) => {
                              return value === watch('password') ? true : 'Password not matched.';
                            }
                          }
                        }
                      )}
                    ></input>
                    <p className='error-text'>{errors.repassword && errors.repassword.message}</p>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary float-right'>
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProviders;
