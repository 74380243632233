import React, { useState, useEffect } from 'react';
import Grid from '../Grid';
import axios from 'axios';
import { format } from 'date-fns';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from 'react-router-dom';
import Alert from '../helpers/Alert';

const ShowUser = () => {
  const [userData, setUserData] = useState([]);
  const history = useHistory();

  const ACTIVE = ['in-active', 'active'];
  const ROLE = ['admin', 'client', 'team lead', 'agent', 'self-auth-admin'];

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const token = localStorage.getItem('token');
    const { data } = await axios.get('/api/user/users-list', { headers: { authorization: token } });
    console.log('getUserData -> data', data);
    setUserData(data);
  };

  const confirmDrop = (user_id) => {
    confirmAlert({
      title: 'Remove Record',
      message: 'Are you sure to remove this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              await axios.delete(`/api/user/delete-user/${user_id}`, { headers: { authorization: token } });
              await getUserData();
            } catch (err) {
              const { data } = err.response;
              Alert('error', 'Server Error', data);
            }
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const columnDefs = [
    { headerName: 'User ID', field: 'user_id' },
    { headerName: 'Username', field: 'username' },
    {
      headerName: 'Active',
      field: 'active',
      cellRendererFramework: ({ value }) => {
        return ACTIVE[value];
      }
    },
    {
      headerName: 'Role',
      field: 'role',
      cellRendererFramework: ({ value }) => {
        return ROLE[value];
      }
    },
    {
      headerName: 'Created Date',
      field: 'created_at',
      cellRendererFramework: ({ value }) => {
        return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
      }
    },
    {
      headerName: 'Updated Date',
      field: 'updated_at',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return data.user_id !== 1 ? (
          <a href='# ' onClick={() => confirmDrop(data.user_id)}>
            <i className='far fa-trash-alt text-danger' title='Delete entry'></i>
          </a>
        ) : null;
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return data.user_id !== 1 ? (
          <a
            href='# '
            onClick={() => {
              history.push('/update-user', data);
            }}
          >
            <i className='fas fa-pencil-alt text-primary' title='Edit entry'></i>
          </a>
        ) : null;
      }
    }
  ];

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>Users List</h4>
            <Grid columnDefs={columnDefs} rowData={userData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowUser;
