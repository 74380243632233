import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Alert from '../helpers/Alert';

/**
 * it creates a new email
 * @param {any} props
 * @returns JSX
 */
const CreateEmail = (props) => {
  const { register, errors, setValue, getValues, handleSubmit } = useForm();
  const [formValues, setFormValues] = useState([]);

  const [companyParams, setCompanyParams] = useState({});
  const [emailList, setEmailList] = useState([]);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      // sets company details for which we will create email
      const companyParams = { ...location.state };
      setCompanyParams(companyParams);
    } else {
      Alert('error', 'Internal Error', "Could't find associated company.");
      history.push('/company-list');
    }
    // eslint-disable-next-line
    }, [])
  /**
   * on enter key submits the email to the list
   * @param  {event} e
   */
  const handleEmailList = (e) => {
    if (e.keyCode === 13 || (e.keyCode === undefined && !_.isEmpty(formValues))) {
      let emailListObj = [];
      emailListObj = [...emailList];
      emailListObj.push(formValues);
      setEmailList(emailListObj);
    }

    setFormValues([]);
  };
  /** generic handler for inputs
   * @param  {string} control
   * @param  {any} value
   */
  const handleControl = (control, value) => {
    setValue(control, value);
    setFormValues({ ...formValues, ...getValues() });
  };
  /**
   *  removes the email from list
   * @param  {number} index
   */
  const removeEmail = (index) => {
    const filteredEmailList = emailList.filter((k, i) => i != index);
    setEmailList(filteredEmailList);
  };
  /**
   * handler to submits the form
   */
  const handleFormSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      if (_.has(companyParams, 'company_id') && !_.isEmpty(emailList)) {
        const { company_id } = companyParams;
        await axios.post('/api/mailprovider/create-email', { company_id, emailList }, { headers: { authorization: token } });
        Alert('success', 'Email Created', 'Email created successfully');
      } else {
        Alert('error', 'Internal Error', 'No company assocaited or empty list provided.');
        history.push('/company-list');
      }
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Create Email @{companyParams.company_name}</h4>
              <form className='form-inline' onSubmit={handleSubmit(handleEmailList)}>
                <div className='form-group'>
                  <div className='col-md-10'>
                    <label className='mr-3 d-block'>Email</label>
                    <input
                      name='email'
                      value={formValues['email'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('email', e.target.value)}
                      ref={register(
                        { name: 'email' },
                        {
                          required: { value: true, message: 'Field is required.' },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'invalid email address'
                          }
                        }
                      )}
                    ></input>
                    <p className='error-text'>{errors.email && errors.email.message}</p>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary'>
                  Add+
                </button>
              </form>
              <div>
                <h4 className='card-title'>Added Emails</h4>
                <ul className='list-group'>
                  {_.isEmpty(emailList) ? (
                    <li className='list-group-item'>No record</li>
                  ) : (
                    emailList.map(({ email }, i) => (
                      <li key={i} className='d-flex list-group-item justify-content-between'>
                        <p className='m-0'>
                          <span className='h5'>@ </span> {email}
                        </p>
                        <a className='btn btn-sm btn-link m-0 text-danger' href='#' onClick={() => removeEmail(i)}>
                          <i className='fas fa-times'></i>
                        </a>
                      </li>
                    ))
                  )}
                </ul>
                <button type='button' onClick={() => handleFormSubmit()} className='btn btn-primary mt-3 float-right'>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEmail;
