import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import Alert from '../helpers/Alert';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
/**
 * update payment conifguration
 * @returns JSX
 */
const UpdatePaymentConfig = () => {
  const { register, errors, setValue, getValues, handleSubmit } = useForm();
  const [formValues, setFormValues] = useState({});
  const [companyOptions, setCompanyOptions] = useState([]);
  const [gatewayOptions, setGatewayOptions] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setFormValues({ ...formValues, ...location.state });
      _.mapValues(location.state, (value, key) => {
        setValue(key, value);
      });
    }
    getGatewayList();
    getCompanyList();
  }, [formValues, location.state, setValue]);
  /**
   * gets the list of gateways
   */
  const getGatewayList = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/gateway', { headers: { authorization: token } });
      const options = [];
      if (_.isArray(data)) {
        data.map(({ gateway_id, gateway_name }) => options.push({ label: gateway_name, value: gateway_id }));
        setGatewayOptions(options);
      } else if (data === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
      }
    } catch (err) {
      Alert('error', 'Error Occured !!', 'Could not load gateway list.Please refresh.', 'fas fa-exclamation');
    }
  };
  /**
   * gets the list of companies
   */
  const getCompanyList = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/company/company-list', { headers: { authorization: token } });
      const options = [];
      if (_.isArray(data)) {
        data.map(({ company_id, company_name }) => options.push({ label: company_name, value: company_id }));
        setCompanyOptions(options);
      } else if (data === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
      }
    } catch (err) {
      Alert('error', 'Error Occured !!', 'Could not load company list.Please refresh.', 'fas fa-exclamation');
      console.log(err);
    }
  };
  /**
   * generic function to handle input
   * @param {string} control
   * @param {any} value
   */
  const handleControl = (control, value) => {
    setValue(control, value);
    setFormValues({ ...formValues, ...getValues() });
  };

  /**
   * handles form submit
   */
  const handleFormSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put('/api/gateway/update-payment-config', { data: formValues }, { headers: { authorization: token } });
      Alert('success', 'Provider Created', 'Provider created successfully');
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Update Payment Configuration</h4>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Payment Gateway</label>
                    <select
                      className='form-control'
                      name='company_id'
                      ref={register({ name: 'gateway_id' }, { required: { value: true, message: 'Field is required.' } })}
                      value={formValues && formValues['gateway_id'] && formValues['gateway_id'].toString()}
                      onChange={(e) => handleControl('gateway_id', e.target.value)}
                    >
                      <option value=''>Select gateway</option>
                      {gatewayOptions &&
                        gatewayOptions.map(({ label, value }, index) => {
                          return (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          );
                        })}
                    </select>
                    <p className='error-text'>{errors.gateway_id && errors.gateway_id.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Company</label>
                    <select
                      className='form-control'
                      name='company_id'
                      ref={register({ name: 'company_id' }, { required: { value: true, message: 'Field is required.' } })}
                      value={formValues && formValues['company_id'] && formValues['company_id'].toString()}
                      onChange={(e) => handleControl('company_id', e.target.value)}
                    >
                      <option value=''>Select company</option>
                      {companyOptions &&
                        companyOptions.map(({ label, value }, index) => {
                          return (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          );
                        })}
                    </select>
                    <p className='error-text'>{errors.company_id && errors.company_id.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>API Key</label>
                    <input
                      name='api_key'
                      value={formValues['api_key'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('api_key', e.target.value)}
                      ref={register({ name: 'api_key' }, { required: { value: true, message: 'Field is required.' } })}
                    ></input>
                    <p className='error-text'>{errors.api_key && errors.api_key.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Secret Key</label>
                    <input
                      name='secret_key'
                      value={formValues['secret_key'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('secret_key', e.target.value)}
                      ref={register({ name: 'secret_key' }, { required: { value: true, message: 'Field is required.' } })}
                    ></input>
                    <p className='error-text'>{errors.secret_key && errors.secret_key.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Group Code</label>
                    <input
                      name='group_code'
                      value={formValues['group_code'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('group_code', e.target.value)}
                      ref={register({ name: 'group_code' }, { required: { value: true, message: 'Field is required.' } })}
                    ></input>
                    <p className='error-text'>{errors.group_code && errors.group_code.message}</p>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary float-right'>
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePaymentConfig;
