import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Alert from '../helpers/Alert';
import Grid from '../Grid';
import { format } from 'date-fns';
import Confirm from '../helpers/Confirm';
import { smsCount } from '../helpers/utils';
import { formatDate, converDateToEuroZone } from '../helpers/utils';
import _ from 'lodash';
import CampaignModal from './CampaignModal';

/**
 * Renders MessageView campaign list
 * its an child component for campaign list.
 */
const CampaignList = () => {
  const columnApi = useRef();

  const [data, setData] = useState(null);
  const [mode, setMode] = useState(false);
  const [type, setType] = useState(false);
  const sentSMSRef = useRef(0);

  const rowClassRules = {
    'ag-row-idle-color': (params) => {
      return params.data.status === 0;
    },
    'ag-row-done-color': (params) => {
      return params.data.status === 1;
    },
    'ag-row-running-color': (params) => {
      return params.data.status === 2;
    },
    'ag-row-batch-color': (params) => {
      return params.data.status === 3;
    }
  };

  const [campaignDetail, setCampaignDetail] = useState({});
  const [linkedContacts, setLinkedContacts] = useState([]);

  const MODE = ['Real-time', 'Batch'];
  const TYPE = ['Visual', 'Simple', 'Template'];
  const CAMPAIGNTYPE = ['Normal', 'API'];
  const STATUS = ['Not Send', 'Sent'];
  useEffect(() => {
    getCompanyData();
  }, []);
  /**
   * aggrio ready function
   * @param  {any} params
   */
  const onGridReady = (params) => {
    columnApi.current = params.columnApi;
  };

  //
  // ─── GRID HEADER ────────────────────────────────────────────────────────────────
  //
  // column list of aggrid
  const columnDefs = [
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return data.campaign_type === 0 ? (
          <a
            href='# '
            onClick={data.status === 3 ? () => startConfirm(data.campaign_id, data.mode) : null}
            role='button'
            aria-disabled='true'
          >
            <i className={data.status === 3 ? 'fas fa-play text-success' : 'fas fa-play text-secondary'} title='Start SMS'></i>
          </a>
        ) : null;
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => confirmDrop(data.campaign_id)}>
            <i className='far fa-trash-alt text-danger' title='Delete entry'></i>
          </a>
        );
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => setCampaginDetails(data)} data-toggle='modal' data-target='#columnDetail'>
            <i className='fas fa-info-circle text-primary' title='information'></i>
          </a>
        );
      }
    },
    { field: 'campaign_type', hide: true },
    { headerName: 'Campaign ID', field: 'campaign_id' },
    { headerName: 'Company Name', field: 'company_name', filter: true },
    { headerName: 'Campaign Name', field: 'campaign_name', filter: true },
    { headerName: 'Provider Name', field: 'provider_name', filter: true },
    { headerName: 'Sender', field: 'sender', filter: true },
    {
      headerName: 'Mode',
      field: 'mode',
      filter: true,
      cellRendererFramework: ({ value }) => {
        return MODE[value];
      }
    },
    {
      headerName: 'Message Type',
      field: 'type',
      filter: true,
      cellRendererFramework: ({ value }) => {
        return TYPE[value];
      }
    },
    { headerName: 'SMS Head', field: 'sms_head', filter: true },
    { headerName: 'SMS Tail', field: 'sms_tail', filter: true },
    { headerName: 'SMS Text', field: 'sms_text', filter: true },
    {
      headerName: 'Schedule Time',
      field: 'schedule_time',
      filter: 'agDateColumnFilter',
      hide: false,
      cellRendererFramework: ({ value }) => {
        return value ? formatDate(converDateToEuroZone(value)) : 'N/A';
      }
    },
    { headerName: 'Duration', field: 'duration', filter: true },
    { headerName: 'Logo', field: 'logo' },
    { headerName: 'SMS URL', field: 'sms_url', filter: true },
    {
      headerName: 'Created Date',
      field: 'created_at',
      filter: 'agDateColumnFilter',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },
    {
      headerName: 'Updated Date',
      field: 'updated_at',
      filter: 'agDateColumnFilter',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },

    // {
    //     headerName: null, width: 30,
    //     cellRendererFramework: (data) => {
    //         return <a href="# " onClick={() => {
    //             props.history.push({
    //                 pathname: '/edit-campaign',
    //                 data
    //             });
    //         }}><i className="fas fa-pencil-alt text-primary" title="Edit entry"></i></a>;
    //     }
    // },
    { headerName: null, field: 'company_id', hide: true },
    { headerName: null, field: 'campaign_id', hide: true }
  ];
  /**get the number of SMS sent by each campaign
   * @param  {number|string} campaignID
   */
  const getSentSMSCount = async (campaignID) => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`/api/sms/sent-sms-by-campaign/${campaignID}`, { headers: { authorization: token } });
      sentSMSRef.current = data[0]['count(*)'];
    } catch (err) {}
  };

  /**
   * sets the detail for info button display in grid
   * @param  {object} data
   */
  const setCampaginDetails = async (data) => {
    try {
      const token = localStorage.getItem('token');
      const resp = await axios.get(`/api/contact/get-contacts/${data.campaign_id}`, { headers: { authorization: token } });
      await getSentSMSCount(data.campaign_id);

      //constructing sms string to count total chars
      let smsString = '';
      smsString += data.sms_head !== null ? data.sms_head : '';
      smsString += data.sms_text !== null ? data.sms_text : '';
      smsString += data.sms_tail !== null ? data.sms_tail : '';

      data['sms_text'] = data['sms_text'] === null ? '' : data['sms_text'];

      const totalSMS = smsCount(smsString);

      if (!data['sms_text'].includes('<p>'))
        data['sms_text'] += `<p><br/><b>${smsString.length} Chars | ${totalSMS} Msgs | ${sentSMSRef.current * totalSMS} Sent Msgs</b></p>`;

      _.map(data, (value, key) => {
        if (key === 'campaign_type') {
          data[key] = CAMPAIGNTYPE[value];
        } else if (key === 'status') {
          data[key] = STATUS[value];
        } else if (key === 'mode') {
          data[key] = MODE[value];
        } else if (key === 'type') {
          data[key] = TYPE[value];
        }
      });

      setCampaignDetail(data);
      setLinkedContacts(resp.data);
    } catch (err) {
      console.log('setCampaginDetails -> err', err);
      //const { data } = err.response;
      //Alert('error', 'Error Occured !!', data, 'fas fa-exclamation');
    }
  };
  /**
   * gets company data
   */
  const getCompanyData = async () => {
    const token = localStorage.getItem('token');
    const { data } = await axios.get('/api/campaign/get-campaign-by-sms', { headers: { authorization: token } });
    if (data === 'token_expired') {
      localStorage.setItem('token', 'token_expired');
    } else {
      setData(data);
    }
  };
  /**
   * display column on the basis of sms mode
   * batch or real-time
   * @param  {array} mode
   */
  const onModeChange = (mode) => {
    setMode(mode);
    columnApi.current.setColumnVisible('schedule_time', mode);
  };
  /**
   * display column on the basic od type selection
   * visual,simple or template
   * @param  {array} type
   */
  const onTypeChange = (type) => {
    setType({ type });
    columnApi.current.setColumnVisible('sms_head', !type);
    columnApi.current.setColumnVisible('sms_tail', !type);
    columnApi.current.setColumnVisible('sms_url', !type);
    columnApi.current.setColumnVisible('sms_message', type);
  };
  /**
   * confirmation box to start campaign
   * @param  {number|string} campaign_id
   * @param  {array} mode
   */
  const startConfirm = (campaign_id, mode) => {
    Confirm('Start Campaign', 'Do you want to start messaging campaign.', () => startCampaign(campaign_id, mode));
  };
  /**
   * starts the campaign
   * @param  {number|string} campaign_id
   * @param  {bool} mode
   */
  const startCampaign = async (campaign_id, mode) => {
    try {
      const token = localStorage.getItem('token');
      const messageCount = await axios.post(
        '/api/sms/start',
        { data: { campaign_id, mode } },
        { headers: { 'Content-Type': 'application/json', authorization: token } }
      );
      if (messageCount === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
        Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
      } else {
        Alert('success', 'success', `${messageCount.data} Messages will be sent. `, 'fa-check-circle');
      }
    } catch (err) {
      Alert('error', 'Error', 'An error occured while deleting record.', 'fa-exclamation');
      console.log(err);
    }
  };
  /**
   * remove the campaign not used anymore due to
   * business logic
   * @param  {number|string} recordID
   */
  const confirmDrop = (recordID) => {
    Confirm('Remove Record', 'Are you sure to remove this record.', () => dropRecord(recordID));
  };
  /**
   * remove the campaign not used anymore due to
   * business logic
   * @param  {number|string} recordID
   */
  const dropRecord = async (recordID) => {
    try {
      const token = localStorage.getItem('token');
      const res = await axios.delete(`/api/campaign/remove-campaign/${recordID}`, {
        headers: { 'Content-Type': 'application/json', authorization: token }
      });
      if (res === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
        Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
      } else {
        Alert('success', 'success', 'Record Deleted successfully', 'fa-check-circle');
      }

      getCompanyData();
    } catch (err) {
      Alert('error', 'Error', 'An error occured while deleting record.', 'fa-exclamation');
      console.log(err);
    }
  };

  return (
    <>
      <Grid rowClassRules={rowClassRules} columnDefs={columnDefs} onGridReady={onGridReady} rowData={data} />
      <CampaignModal campaignDetail={campaignDetail} linkedContacts={linkedContacts} />
    </>
  );
};

export default CampaignList;
