import React from 'react';
import Routes from './Routes';
import './App.css';
/**
 * App includes all the Routes component
 * starting it's the starting point of the
 * project.
 * @return JSX
 */
function App() {
  return <Routes />;
}

export default App;
