import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Alert from '../helpers/Alert';
/**
 * It renders the list of companies belong to API Campaign.
 */
const ApiCampaginList = () => {
  const [campaignData, setCampaignData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const companyID = location.state.company_id;
      getCampaignList(companyID);
    }
  }, [location.state]);
  /**
   * generic function to handle
   * radio input
   *
   * @param  {string} control
   * @param  {bool} value
   */
  const handleRadio = (control, value) => {
    setFormValues({ ...formValues, [control]: value });
  };
  /**
   * handles form submit method
   */
  const handleSubmit = async () => {
    try {
      const data = campaignData.filter((o) => o.campaign_id === formValues.currentActive);
      const { company_id, campaign_id } = data[0];
      const token = localStorage.getItem('token');
      await axios.put('/api/campaign/update-api-campaign', { company_id, campaign_id }, { headers: { authorization: token } });
      Alert('success', 'Campaign Saved', 'Campaign saved and actived.');
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };
  /**
   * gets the list of API campaign by
   * companyID.
   * @param  {number|string} companyID
   */
  const getCampaignList = async (companyID) => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`/api/campaign/get-api-campaign/${companyID}`, { headers: { authorization: token } });
      const currentActive = data.filter((o) => o.api_campaign_active === 1);
      if (currentActive.length > 0) {
        setFormValues({ currentActive: currentActive[0].campaign_id });
      }
      setCampaignData(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='card'>
          <div className='card-body'>
            <h4 className='card-title'>API Campaigns</h4>
            <div className='list-group'>
              {campaignData.length > 0 ? (
                campaignData.map(({ campaign_id, campaign_name }, index) => {
                  return (
                    <div key={index}>
                      <input
                        id={campaign_id}
                        type='radio'
                        name='campaignList'
                        value={campaign_id}
                        checked={formValues['currentActive'] === campaign_id ? true : false}
                        onChange={(e) => handleRadio('currentActive', parseInt(e.target.value))}
                      />
                      <label className='list-group-item' htmlFor={campaign_id}>
                        {campaign_name}
                      </label>
                    </div>
                  );
                })
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <button className='btn btn-primary float-right mt-2' onClick={() => handleSubmit()}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiCampaginList;
