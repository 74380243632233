import React from 'react';
import _ from 'lodash';
/**
 * Wrapper component for the CreateCampaign.js keeps the resuable fields
 * to render with other components.
 * @param  {function} {handleControl
 * @param  {object} formValues
 * @param  {array} companyOptions
 * @param  {function} register
 * @param  {object} errors}
 */
const CampaignBase = ({ handleControl, formValues, companyOptions, register, errors }) => {
  return (
    <>
      <div className='form-group'>
        <div className='col-md-9'>
          <label className='mr-3'>Company Name</label>
          <select
            className='form-control col-sm-11 d-inline mr-1'
            name='company_id'
            onChange={(e) => handleControl('company_id', e.target.value)}
            value={formValues['company_id']}
            disabled={_.isEmpty(companyOptions)}
            ref={register({ name: 'company_id' }, { required: { value: true, message: 'Field is required.' } })}
          >
            <option>Select Company</option>
            {companyOptions.map(({ label, value }, index) => {
              return (
                <option key={index} value={value}>
                  {label}
                </option>
              );
            })}
          </select>
          {_.isEmpty(companyOptions) && (
            <span className='input-group-addon'>
              <i className='fa fa-refresh fa-spin'></i>
            </span>
          )}
          <p className='error-text'>{errors.company_id && errors.company_id.message}</p>
        </div>
      </div>
      <div className='form-group'>
        <div className='col-md-9'>
          <label className='mr-3'>Campaign Type</label>
          <select
            className='form-control col-sm-11 d-inline mr-1'
            name='campaign_type'
            onChange={(e) => handleControl('campaign_type', e.target.value)}
            value={formValues['campaign_type']}
            ref={register({ name: 'campaign_type' })}
          >
            <option value='0'>SMS Filer</option>
            <option value='1'>SMS API</option>
            <option value='2'>Mail Filer</option>
            <option value='3'>Mail API</option>
          </select>
        </div>
      </div>
      <div className='form-group'>
        <div className='col-md-9'>
          <label className='mr-3'>Campaign Name</label>
          <input
            className='form-control'
            name='campaign_name'
            onChange={(e) => handleControl('campaign_name', e.target.value)}
            value={(formValues && formValues['campaign_name']) || ''}
            ref={register({ name: 'campaign_name' }, { required: { value: true, message: 'Field is required.' } })}
          />
          <p className='error-text'>{errors.campaign_name && errors.campaign_name.message}</p>
        </div>
      </div>
    </>
  );
};

export default CampaignBase;
