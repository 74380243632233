import React from 'react';
/**
 * displays reporting
 * @returns JSX
 */
const Reporting = () => {
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Reporting</h4>
              <div className='row'>
                <div className='col-sm-3'>
                  <div className='card card-hover'>
                    <div className='box bg-success text-center'>
                      <a href='/sms-reporting'>
                        <h1 className='font-light text-white'>
                          <i className='mdi mdi-message-text-outline'></i>
                        </h1>
                        <h6 className='text-white'>Message Status</h6>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='card card-hover'>
                    <div className='box bg-primary text-center'>
                      <a href='/payment-report'>
                        <h1 className='font-light text-white'>
                          <i className='mdi mdi-currency-eur'></i>
                        </h1>
                        <h6 className='text-white'>Payment Status</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reporting;
