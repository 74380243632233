import React from 'react';
import _ from 'lodash';

import DatePicker from 'react-datepicker';
/**
 * Reusable component Renders mail inputs for mail campaign configuration
 * it an child component of CreateCampaign handles the feature to create
 * mail campaign.
 * @param  {function} handleControl
 * @param  {onject} formValues
 * @param  {array} providerOptions
 * @param  {function} register
 * @param  {object} errors
 * @param  {bool} mode
 * @param  {function} setMode
 * @param  {bool} showExpiry
 * @param  {bool} expiryMode
 * @param  {function} handleRadio
 * @param  {array} aliasOptions
 * @param  {array} mailTemplateOptions}
 */
const MailBase = ({
  handleControl,
  formValues,
  providerOptions,
  register,
  errors,
  mode,
  setMode,
  showExpiry,
  expiryMode,
  handleRadio,
  aliasOptions,
  mailTemplateOptions
}) => {
  return (
    <>
      <div className='form-group'>
        <div className='col-md-9'>
          <label className='mr-3'>Provider Name</label>
          <select
            className='form-control col-sm-11 d-inline mr-1'
            name='mail_provider_id'
            disabled={_.isEmpty(providerOptions)}
            onChange={(e) => handleControl('mail_provider_id', e.target.value)}
            value={formValues['mail_provider_id']}
            ref={register({ name: 'mail_provider_id' }, { required: { value: true, message: 'Field is required.' } })}
          >
            <option>Select Provider</option>
            {providerOptions.map(({ label, value }, index) => {
              return (
                <option key={index} value={value}>
                  {label}
                </option>
              );
            })}
          </select>
          {_.isEmpty(providerOptions) && (
            <span className='input-group-addon'>
              <i className='fa fa-refresh fa-spin'></i>
            </span>
          )}
          <p className='error-text'>{errors.mail_provider_id && errors.mail_provider_id.message}</p>
        </div>
      </div>
      <div className='form-group'>
        <div className='col-md-9'>
          <label className='mr-3'>Sender</label>
          <select
            className='form-control col-sm-11 d-inline mr-1'
            name='sender'
            onChange={(e) => handleControl('sender', e.target.value)}
            disabled={_.isEmpty(aliasOptions)}
            value={formValues['sender']}
            ref={register({ name: 'sender' }, { required: { value: true, message: 'Field is required.' } })}
          >
            <option>Select Sender</option>
            {aliasOptions.map((value, index) => {
              return (
                <option key={index} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
          {_.isEmpty(aliasOptions) && !_.isEmpty(formValues['provider_id']) && (
            <span className='input-group-addon'>
              <i className='fa fa-refresh fa-spin'></i>
            </span>
          )}
          <p className='error-text'>{errors.sender && errors.sender.message}</p>
        </div>
      </div>
      <div className='form-group'>
        <div className='col-md-9'>
          <label className='mr-3'>Active Template</label>
          <select
            className='form-control col-sm-11 d-inline mr-1'
            name='mail_template_id'
            onChange={(e) => handleControl('mail_template_id', e.target.value)}
            value={formValues['mail_template_id']}
            disabled={_.isEmpty(mailTemplateOptions)}
            ref={register({ name: 'mail_template_id' }, { required: { value: true, message: 'Field is required.' } })}
          >
            <option>Select template</option>
            {mailTemplateOptions.map(({ label, value }, index) => {
              return (
                <option key={index} value={value}>
                  {label}
                </option>
              );
            })}
          </select>
          {_.isEmpty(mailTemplateOptions) && !_.isEmpty(formValues['provider_id']) && (
            <span className='input-group-addon'>
              <i className='fa fa-refresh fa-spin'></i>
            </span>
          )}
          <p className='error-text'>{errors.mail_template_id && errors.mail_template_id.message}</p>
        </div>
      </div>

      <label className='form-control'>Mode</label>
      <div className='form-check form-check-inline d-flex'>
        <div className='custom-control custom-radio mr-3'>
          <input
            type='radio'
            className='custom-control-input'
            checked={mode['realtime']}
            id='realtime'
            value={0}
            name='mode'
            onChange={(e) => {
              setMode({ realtime: true, batch: false });
              delete formValues['schedule_time'];
              handleControl('mode', e.target.value);
            }}
          />
          <label className='custom-control-label form-control' htmlFor='realtime'>
            Real-time
          </label>
        </div>
        <div className='custom-control custom-radio mr-3'>
          <input
            type='radio'
            className='custom-control-input'
            checked={mode['batch']}
            id='batch'
            name='mode'
            value={1}
            onChange={(e) => {
              setMode({ realtime: false, batch: true });
              handleControl('mode', e.target.value);
            }}
          />
          <label className='custom-control-label form-control' htmlFor='batch'>
            Batch
          </label>
        </div>
      </div>

      {showExpiry && (
        <>
          <label className='form-control'>Expiry Mode</label>
          <div className='form-check form-check-inline d-flex'>
            <div className='custom-control custom-radio mr-3'>
              <input
                type='radio'
                className='custom-control-input'
                value={0}
                checked={expiryMode['visited']}
                onChange={(e) => {
                  handleRadio('visited', e.target.value);
                }}
                id='visited'
                name='expiryMode'
              />
              <label className='custom-control-label form-control' htmlFor='visited'>
                Visited
              </label>
            </div>
            <div className='custom-control custom-radio mr-3'>
              <input
                type='radio'
                className='custom-control-input form-control'
                id='tries'
                value={1}
                checked={expiryMode['tries']}
                onChange={(e) => {
                  handleRadio('tries', e.target.value);
                }}
                name='expiryMode'
              />
              <label className='custom-control-label form-control' htmlFor='tries'>
                Tries
              </label>
            </div>
            <div className='custom-control custom-radio mr-3'>
              <input
                type='radio'
                className='custom-control-input'
                value={2}
                checked={expiryMode['noexpiry']}
                onChange={(e) => {
                  handleRadio('noexpiry', e.target.value);
                }}
                id='noexpiry'
                name='expiryMode'
              />
              <label className='custom-control-label form-control' htmlFor='noexpiry'>
                No Expiry
              </label>
            </div>
          </div>
          {expiryMode['tries'] || expiryMode['noexpiry'] ? (
            <>
              <div className='form-group'>
                <div className='col-md-9'>
                  <label className='form-control'>Max Tries</label>
                  <input
                    name='max_tries'
                    className='form-control'
                    onChange={(e) => handleControl('max_tries', e.target.value)}
                    value={(formValues && formValues['max_tries']) || 0}
                    type='number'
                    ref={register({ name: 'max_tries' }, { required: { value: true, message: 'Field is required.' } })}
                  />
                  <p className='error-text'>{errors.max_tries && errors.max_tries.message}</p>
                </div>
              </div>
            </>
          ) : null}
          {!expiryMode['noexpiry'] ? (
            <div className='form-group'>
              <div className='col-md-9'>
                <label>Duration (hours)</label>
                <input
                  name='duration'
                  className='form-control'
                  onChange={(e) => handleControl('duration', e.target.value)}
                  value={(formValues && formValues['duration']) || 72}
                  type='number'
                  ref={register({ name: 'duration' }, { required: { value: true, message: 'Field is required.' } })}
                />
                <p className='error-text'>{errors.duration && errors.duration.message}</p>
              </div>
            </div>
          ) : null}
          {mode['batch'] ? (
            <>
              <label className='custom-control-label form-control' htmlFor='batch'>
                Date/Time
              </label>
              <DatePicker
                name='schedule_time'
                showTimeSelect
                timeFormat='HH:mm'
                dateFormat='yyyy-MM-dd HH:mm'
                timeIntervals={5}
                timeCaption='Time'
                selected={formValues['schedule_time'] || undefined}
                onChange={(date) => handleControl('schedule_time', date)}
                className='form-control'
                ref={register({ name: 'schedule_time' }, { required: { value: true, message: 'Field is required.' } })}
              />
              <p className='error-text'>{errors.schedule_time && errors.schedule_time.message}</p>
            </>
          ) : null}
        </>
      )}
      <div className='form-group'>
        <div className='col-md-9'>
          <label>URL</label>
          <input
            className='form-control'
            name='sms_url'
            onChange={(e) => handleControl('sms_url', e.target.value)}
            value={(formValues && formValues['sms_url']) || ''}
            ref={register({ name: 'sms_url' }, { required: { value: true, message: 'Field is required.' } })}
          />
          <p className='error-text'>{errors.sms_url && errors.sms_url.message}</p>
        </div>
      </div>
    </>
  );
};

export default MailBase;
