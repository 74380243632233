import React, { useState, useEffect } from 'react';
import Grid from '../Grid';
import axios from 'axios';
import Alert from '../helpers/Alert';
import Confirm from '../helpers/Confirm';
/**
 * display alias from skebby using
 * Skebby Alias API
 * @param {any} props
 * @returns JSX
 */
const ShowSkebbyAlias = (props) => {
  const { provider_id } = props;

  const ALIAS = [
    'Unconfirmed',
    'Confirmed by Skebby',
    'Confirmed by AGCOM',
    'Blocked by Skebby',
    'Blocked by AGCOM',
    'The Alias has been pre - confirmed by Skebby'
  ];

  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    if (provider_id) {
      getAlias();
    }
    // eslint-disable-next-line
    }, [provider_id]);
  /**
   * gets alias from skebby
   */
  const getAlias = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`/api/provider/get-alias/${provider_id}`, { headers: { authorization: token } });
      setGridData(data.alias);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };
  // column definations
  const columnDefs = [
    { headerName: 'Alias ID', field: 'id-alias' },
    {
      headerName: 'Alias State',
      field: 'alias-state',
      cellRendererFramework: ({ value }) => {
        return value ? ALIAS[value] : 'N/A';
      }
    },
    { headerName: 'Company Name', field: 'company-name' },
    { headerName: 'Contact Name', field: 'contact-name' },
    { headerName: 'Contact Surname', field: 'contact-surname' },
    { headerName: 'Cod-Fiscale', field: 'cod-fiscale' },
    { headerName: 'VAT-Number', field: 'vat-number' },
    { headerName: 'Contact Address', field: 'contact-address' },
    { headerName: 'Contact City', field: 'contact-city' },
    { headerName: 'Postcode', field: 'contact-pcode' },
    { headerName: 'Contact Type', field: 'contact-type' },
    { headerName: 'Web Address', field: 'contact-info' },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        /*eslint-disable-next-line*/
                return <a href="# " onClick={() => confirm(data['id-alias'])}><i className="far fa-trash-alt text-danger" title="Delete entry"></i></a>;
      }
    }
  ];
  /**
   * removes alias
   * @param {number} aliasID
   */
  const confirm = (aliasID) => {
    Confirm('Remove Alias', 'Do you want to delete this alias.', async () => {
      try {
        const token = localStorage.getItem('token');
        await axios.post('/api/provider/remove-alias', { aliasID, provider_id }, { headers: { authorization: token } });
        Alert('success', 'Alias Deleted', 'Alias successfully deleted.', 'fa-check-circle');
        getAlias();
      } catch (err) {
        const { data } = err.response;
        Alert('error', 'Server Error', data);
      }
    });
  };

  return (
    <div>
      <Grid columnDefs={columnDefs} rowData={gridData} />
    </div>
  );
};

export default ShowSkebbyAlias;
