import React from 'react';
import ReactHtmlParser from 'react-html-parser';
/**
 * Displays the list of fields in detail for each campaign when click info button
 * in the grid.
 * @param  {object} {campaignDetail
 * @param  {string} linkedContacts}
 */
const CampaignModal = ({ campaignDetail, linkedContacts }) => {
  return (
    <div className='modal fade modal-height' id='columnDetail' role='dialog' aria-labelledby='columnDetailLabel' aria-hidden='true'>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='columnDetailLabel'>
              Campaign Detail
            </h5>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <ul className='list-group'>
              {Object.keys(campaignDetail).map((value, index) => {
                return (
                  <li key={index} className='list-group-item d-flex justify-content-between align-items-center'>
                    <span className='text-capitalize'>{value.replace('_', ' ')}</span>
                    <span className='text-right'>{ReactHtmlParser(campaignDetail[value])}</span>
                  </li>
                );
              })}
            </ul>
            <label>Phone Numbers</label>
            <textarea defaultValue={linkedContacts} rows='5'></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignModal;
