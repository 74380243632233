import React, { useState, useEffect, useRef } from 'react';
import { MailViewColumns, rowClassRules } from './MailViewGridElements';
import axios from 'axios';
import Alert from '../helpers/Alert';
import Grid from '../Grid';
import Confirm from '../helpers/Confirm';
import CampaignModal from './CampaignModal';
/**
 * Renders mail campaign list view
 * its an child component for campaign list.
 */
const MailView = () => {
  const columnApi = useRef();

  const [data, setData] = useState(null);

  useEffect(() => {
    getCompanyData();
  }, []);
  /**
   * Aggrid ready Event
   * @param  {object} params
   */
  const onGridReady = (params) => {
    columnApi.current = params.columnApi;
  };
  /**
   * gets company list
   */
  const getCompanyData = async () => {
    const token = localStorage.getItem('token');
    const { data } = await axios.get('/api/campaign/get-campaign-by-mail', { headers: { authorization: token } });
    if (data === 'token_expired') {
      localStorage.setItem('token', 'token_expired');
    } else {
      setData(data);
    }
  };
  /**
   * confirm box as for the confirmation,
   * if want to start you want to start
   * the campaign.
   * campaign
   * @param  {number|string} campaign_id
   * @param  {bool} mode
   */
  const startConfirm = (campaign_id, mode) => {
    Confirm('Start Campaign', 'Do you want to start messaging campaign.', () => startCampaign(campaign_id, mode));
  };
  /**
   * starts the campaign as soon
   * as confirmation is positive
   * @param  {number|string} campaign_id
   * @param  {bool} mode
   */
  const startCampaign = async (campaign_id, mode) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        '/api/mail/start',
        { data: { campaign_id, mode } },
        { headers: { 'Content-Type': 'application/json', authorization: token } }
      );
      if (response === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
        Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
      } else {
        Alert('success', 'success', `${response.data} Messages will be sent. `, 'fa-check-circle');
      }
    } catch (err) {
      Alert('error', 'Error', 'An error occured while deleting record.', 'fa-exclamation');
      console.log(err);
    }
  };
  // gets list of columns
  const columnDefs = MailViewColumns(
    startConfirm,
    () => {},
    () => {}
  );

  return (
    <>
      <Grid rowClassRules={rowClassRules} columnDefs={columnDefs} onGridReady={onGridReady} rowData={data} />
    </>
  );
};
export default MailView;
