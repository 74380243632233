import React from 'react';
import { Redirect, Route } from 'react-browser-router';
import Main from './components/Main';
/**
 * authenticates users to visit private routes,
 * else will promot login.
 * @param  {Component} component - react component
 * @param  {bool} authenticated - user validation
 * @param  {object} rest - copies extra props
 */
const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Main>
            <Component {...props} />
          </Main>
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  );
};

export default PrivateRoute;
