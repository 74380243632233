import React from 'react';
import Editor from 'react-simple-code-editor';
import Highlight, { defaultProps } from 'prism-react-renderer';
import { languages } from 'prismjs/components/prism-core';
import theme from 'prism-react-renderer/themes/nightOwl';
import styled from 'styled-components';
import Alert from '../helpers/Alert';
import _ from 'lodash';
/**
 * its an code highlighter for html to create templates
 * @param {function} handleControl - handle the input in code editor
 * @param {object} formValues - states of all the controls
 * @param {function} register - register input for validation
 * @param {onject} errors - display validation errors
 * @returns JSX
 */
const CodeEditor = ({ handleControl, formValues, register, errors }) => {
  // styles
  const Pre = styled.pre`
    text-align: left;
    margin: 1em 0;
    padding: 0.5em;
    overflow: scroll;
  `;

  const Line = styled.div`
    display: table-row;
  `;

  const LineNo = styled.span`
    display: table-cell;
    text-align: right;
    padding-right: 1em;
    user-select: none;
    opacity: 0.5;
  `;

  const LineContent = styled.span`
    display: table-cell;
  `;
  /**
   * preview shows the HTML code in browser
   * to see what it really looks like
   */
  const preview = () => {
    if (_.has(formValues, 'template_html')) {
      const _window = window.open('about:blank', '_blank');
      _window.document.write(formValues.template_html || '');
      _window.document.close();
    } else {
      Alert('error', 'No HTML', 'Nothing to display');
    }
  };
  /**
   * highlights the code syntax
   * @param {string} code
   * @returns JSX
   */
  const highlight = (code) => (
    <Highlight {...defaultProps} theme={theme} code={code} language='jsx'>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Pre className={className} style={style}>
          {tokens.map((line, i) => (
            <Line key={i} {...getLineProps({ line, key: i })}>
              <LineNo>{i + 1}</LineNo>
              <LineContent>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </LineContent>
            </Line>
          ))}
        </Pre>
      )}
    </Highlight>
  );

  return (
    <>
      <div className='mb-2' style={{ height: '300px', overflow: 'scroll' }}>
        <Editor
          value={(formValues && formValues.template_html) || ''}
          onValueChange={(code) => handleControl('template_html', code)}
          highlight={(code) => highlight(code, languages.jsx)}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12
          }}
          ref={register({ name: 'template_html' }, { required: { value: true, message: 'Field is required.' } })}
        />
        <p className='error-text'>{errors.template_html && errors.template_html.message}</p>
      </div>
      <button type='button' onClick={preview} className='btn btn-primary btn-sm  btn-block'>
        Preview
      </button>
    </>
  );
};

export default CodeEditor;
