import React, { useState } from 'react';
import axios from 'axios';
import Alert from './helpers/Alert';
import { useHistory } from 'react-router-dom';
import { decrypt } from '../Encrypter';

const Sidebar = () => {
  const [companyList, setCompanyList] = useState([]);
  const history = useHistory();
  const role = localStorage.getItem('role') ? parseInt(decrypt(localStorage.getItem('role'))) : 0;
  const getCompanyList = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/company/company-by-api', { headers: { authorization: token } });
      setCompanyList(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <aside className='left-sidebar' data-sidebarbg='skin5'>
      <div className='scroll-sidebar'>
        <nav className='sidebar-nav'>
          {role === 0 ? (
            <ul id='sidebarnav' className='p-t-30 in'>
              <li className='sidebar-item'>
                <a className='sidebar-link has-arrow waves-effect waves-dark' href='#' aria-expanded='false'>
                  <i className='mdi mdi-receipt'></i>
                  <span className='hide-menu'>Companies </span>
                </a>
                <ul aria-expanded='false' className='collapse  first-level'>
                  <li className='sidebar-item'>
                    <a href='/company-list' className='sidebar-link'>
                      <i className='mdi mdi-note-outline'></i>
                      <span className='hide-menu'> Show Companies </span>
                    </a>
                  </li>
                  <li className='sidebar-item'>
                    <a href='/create-company' className='sidebar-link'>
                      <i className='mdi mdi-note-plus'></i>
                      <span className='hide-menu'> Create Company </span>
                    </a>
                  </li>
                  <li className='sidebar-item'>
                    <a href='/create-payment-config' className='sidebar-link'>
                      <i className='mdi mdi-note-plus'></i>
                      <span className='hide-menu'>Create Payment Config </span>
                    </a>
                  </li>
                  <li className='sidebar-item'>
                    <a href='/payment-config-list' className='sidebar-link'>
                      <i className='mdi mdi-note-plus'></i>
                      <span className='hide-menu'> Show Payment Config </span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-item'>
                <a className='sidebar-link has-arrow waves-effect waves-dark' href='#' aria-expanded='false'>
                  <i className='mdi mdi-face'></i>
                  <span className='hide-menu'>campaign</span>
                </a>
                <ul aria-expanded='false' className='collapse  first-level'>
                  <li className='sidebar-item'>
                    <a href='/campaign-list' className='sidebar-link'>
                      <i className='mdi mdi-emoticon'></i>
                      <span className='hide-menu'> Show campaign </span>
                    </a>
                  </li>
                  <li className='sidebar-item'>
                    <a href='/create-campaign' className='sidebar-link'>
                      <i className='mdi mdi-emoticon-cool'></i>
                      <span className='hide-menu'> Create campaign </span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-item'>
                <a className='sidebar-link waves-effect waves-dark sidebar-link' href='/batch-file' aria-expanded='false'>
                  <i className='mdi mdi-pencil'></i>
                  <span className='hide-menu'>Files</span>
                </a>
              </li>
              <li className='sidebar-item'>
                <a className='sidebar-link waves-effect waves-dark sidebar-link' href='/reporting' aria-expanded='false'>
                  <i className='mdi mdi-pencil'></i>
                  <span className='hide-menu'>Reporting</span>
                </a>
              </li>
              <li className='sidebar-item'>
                <a className='sidebar-link has-arrow waves-effect waves-dark' href='#' aria-expanded='false'>
                  <i className='mdi mdi-face'></i>
                  <span className='hide-menu'>SMS Connections</span>
                </a>
                <ul aria-expanded='false' className='collapse  first-level'>
                  <li className='sidebar-item'>
                    <a href='/providers' className='sidebar-link'>
                      <i className='mdi mdi-emoticon'></i>
                      <span className='hide-menu'> Show connections </span>
                    </a>
                  </li>
                  <li className='sidebar-item'>
                    <a href='/create-providers' className='sidebar-link'>
                      <i className='mdi mdi-emoticon-cool'></i>
                      <span className='hide-menu'> Create connection </span>
                    </a>
                  </li>
                  <li className='sidebar-item'>
                    <a href='/create-alias' className='sidebar-link'>
                      <i className='mdi mdi-emoticon-cool'></i>
                      <span className='hide-menu'> Create alias </span>
                    </a>
                  </li>
                  <li className='sidebar-item'>
                    <a href='/show-alias' className='sidebar-link'>
                      <i className='mdi mdi-emoticon-cool'></i>
                      <span className='hide-menu'> Show alias </span>
                    </a>
                  </li>
                </ul>
              </li>

              <li className='sidebar-item'>
                <a className='sidebar-link has-arrow waves-effect waves-dark' href='#' aria-expanded='false'>
                  <i className='mdi mdi-face'></i>
                  <span className='hide-menu'>Mail Connections</span>
                </a>
                <ul aria-expanded='false' className='collapse  first-level'>
                  <li className='sidebar-item'>
                    <a href='/mail-providers' className='sidebar-link'>
                      <i className='mdi mdi-emoticon'></i>
                      <span className='hide-menu'> Show connections </span>
                    </a>
                  </li>
                  <li className='sidebar-item'>
                    <a href='/create-mail-providers' className='sidebar-link'>
                      <i className='mdi mdi-emoticon-cool'></i>
                      <span className='hide-menu'> Create connection </span>
                    </a>
                  </li>
                </ul>
              </li>

              <li className='sidebar-item'>
                <a
                  className='sidebar-link has-arrow waves-effect waves-dark'
                  href='#'
                  onClick={() => getCompanyList()}
                  aria-expanded='false'
                >
                  <i className='mdi mdi-face'></i>
                  <span className='hide-menu'>Api Connections</span>
                </a>
                <ul aria-expanded='false' className='collapse  first-level'>
                  {companyList.length > 0 ? (
                    companyList.map(({ company_id, company_name }, index) => {
                      return (
                        <>
                          <li className='sidebar-item' key={index}>
                            <a href='#' onClick={() => history.push('/campaign-apis-list', { company_id })} className='sidebar-link'>
                              <i className='mdi mdi-emoticon'></i>
                              <span className='hide-menu'>{company_name}</span>
                            </a>
                          </li>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <li className='sidebar-item'>
                        <a href='#' className='sidebar-link'>
                          <i className='mdi mdi-emoticon'></i>
                          <span className='hide-menu'>Loading....</span>
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              </li>

              <li className='sidebar-item'>
                <a className='sidebar-link has-arrow waves-effect waves-dark' href='#' aria-expanded='false'>
                  <i className='mdi mdi-face'></i>
                  <span className='hide-menu'>Users</span>
                </a>
                <ul aria-expanded='false' className='collapse  first-level'>
                  <li className='sidebar-item'>
                    <a href='/create-user' className='sidebar-link'>
                      <i className='mdi mdi-emoticon'></i>
                      <span className='hide-menu'> Create Users </span>
                    </a>
                  </li>
                  <li className='sidebar-item'>
                    <a href='/show-user' className='sidebar-link'>
                      <i className='mdi mdi-emoticon-cool'></i>
                      <span className='hide-menu'> Show Users </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          ) : (
            <ul id='sidebarnav' className='p-t-30 in'>
              <li className='sidebar-item'>
                <a className='sidebar-link has-arrow waves-effect waves-dark' href='#' aria-expanded='false'>
                  <i className='mdi mdi-receipt'></i>
                  <span className='hide-menu'>Companies </span>
                </a>
                <ul aria-expanded='false' className='collapse  first-level'>
                  <li className='sidebar-item'>
                    <a href='/company-list' className='sidebar-link'>
                      <i className='mdi mdi-note-outline'></i>
                      <span className='hide-menu'> Show Companies </span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className='sidebar-item'>
                <a className='sidebar-link waves-effect waves-dark sidebar-link' href='/reporting' aria-expanded='false'>
                  <i className='mdi mdi-pencil'></i>
                  <span className='hide-menu'>Reporting</span>
                </a>
              </li>
            </ul>
          )}
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
