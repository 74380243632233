import React, { useState } from 'react';
import axios from 'axios';
import Form from 'react-basic-form';
import _ from 'lodash';
import Alert from './helpers/Alert';
import { encrypt } from '../Encrypter';
import { COMPANYID } from '../config';

/**
 * simple login component
 * @param {any} props
 * @returns JSX
 */
const Login = (props) => {
  const [error, setError] = useState({});
  const [formValues, setFormValues] = useState({});

  if (localStorage.getItem('token') === 'token_expired' && _.isEmpty(formValues)) {
    Alert('error', 'session expired', 'Session Expired please login again.', 'fas fa-exclamation');
  }

  if (props.authenticated) {
    props.history.push('/company-list');
  }
  /**
   * generic function to handle input
   * @param {string} control
   * @param {any} value
   */
  const handleControl = (formControl, value) => {
    setFormValues({ ...formValues, [formControl]: value });
  };
  /**
   * form submit
   */
  const handleSubmit = async (data) => {
    try {
      const result = await axios.post('/api/user/verify', { data });
      if (result.data.token) {
        localStorage.setItem('token', result.data.token);
        localStorage.setItem('role', encrypt(result.data.role));
        if (result.data.role === 1) {
          localStorage.setItem('access_id', encrypt(result.data.company_id));
        }
        window.location.reload();
      }
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Error Occured !!', data, 'fas fa-exclamation');
    }
  };

  return (
    <div className='fluid-container h-100'>
      <div className='row h-100 justify-content-center align-items-center vertical-center'>
        <div className='col-md-6'>
          <div className='card'>
            <div className='form-horizontal'>
              <div className='card-body'>
                <h4 className='card-title'>Login</h4>
                <Form
                  onSubmit={(data) => handleSubmit(data)}
                  validations={{
                    username: () => {
                      if (_.isEmpty(formValues['username'])) {
                        setError({ ...error, username: 'This field is required.' });
                        return false;
                      } else if (formValues['username'].length < 3) {
                        setError({ ...error, username: 'Password length cannnot be less than 3.' });
                        return false;
                      } else {
                        setError({ ...error, username: '' });
                        return true;
                      }
                    },
                    password: () => {
                      if (_.isEmpty(formValues['password'])) {
                        setError({ ...error, password: 'This field is required.' });
                        return false;
                      } else if (formValues['password'].length < 8) {
                        setError({ ...error, password: 'Password length cannnot be less than 8.' });
                        return false;
                      } else {
                        setError({ ...error, password: '' });
                        return true;
                      }
                    }
                  }}
                  errorMessages={{
                    username: error['username'],
                    password: error['password'],
                    repassword: error['repassword']
                  }}
                >
                  <div className='form-group'>
                    <input type='hidden' name='company_id' value={COMPANYID} />
                    <Form.Element
                      className='form-control'
                      name='username'
                      onChange={(e) => handleControl('username', e.target.value)}
                      type='text'
                      label='Username'
                      value={formValues['username'] || ''}
                      required
                    />
                    <Form.Element
                      className='form-control'
                      name='password'
                      onChange={(e) => handleControl('password', e.target.value)}
                      type='password'
                      label='Password'
                      value={formValues['password'] || ''}
                      required
                    />
                  </div>
                  <Form.Submit text='Submit' className='btn btn-primary float-right' />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
