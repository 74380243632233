import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '../helpers/Alert';
import ShowSkebbyAlias from './ShowSkebbyAlias';
import ShowGeneralAlias from './ShowGeneralAlias';
/**
 * display list of alias belong to
 * each provider account
 * @returns JSX
 */
const ShowAlias = () => {
  const [providerTypesOptions, setProviderTypesOptions] = useState([]);
  const [providerListOptions, setProviderListOptions] = useState([]);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    getProviderTypes();
  }, []);

  useEffect(() => {
    if (formValues['provider_type_id']) {
      getProviderList();
    }
    // eslint-disable-next-line
    }, [formValues['provider_type_id']]);

  console.log(formValues);
  /**
   * generic function to handle input
   * @param {string} control
   * @param {any} value
   */
  const handleControl = (control, value) => {
    setFormValues({ ...formValues, [control]: value });
  };
  /**
   * get the list of providers
   */
  const getProviderList = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`/api/provider/provider-by-type/${formValues['provider_type_id']}`, {
        headers: { authorization: token }
      });
      setProviderListOptions(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };
  /**
   * gets the list of provider types
   */
  const getProviderTypes = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/provider/provider-types', { headers: { authorization: token } });
      setProviderTypesOptions(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Select Provider</h4>
              <div className='form-group'>
                <div className='col-md-9'>
                  <select
                    name='provider_type_id'
                    className='form-control  form-control-lg'
                    onChange={(e) => handleControl('provider_type_id', e.target.value)}
                  >
                    <option value=''>Select Provider</option>
                    {providerTypesOptions.map(({ provider_type_id, provider_name }, idx) => {
                      return (
                        <option key={idx} value={provider_type_id}>
                          {provider_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='form-group'>
                <div className='col-md-9'>
                  <select
                    name='provider'
                    value={formValues['provider_id']}
                    className='form-control  form-control-lg'
                    onChange={(e) => handleControl('provider_id', e.target.value)}
                  >
                    <option value=''>Select Provider</option>
                    {providerListOptions.map(({ provider_id, provider_name }, idx) => {
                      return (
                        <option key={idx} value={provider_id}>
                          {provider_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {formValues && formValues['provider_type_id'] === '1'
                ? formValues['provider_id'] && <ShowSkebbyAlias provider_id={formValues['provider_id']} />
                : formValues && (formValues['provider_type_id'] === '2' || '4')
                ? formValues['provider_id'] && <ShowGeneralAlias provider_id={formValues['provider_id']} />
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowAlias;
