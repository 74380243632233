import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import Alert from '../helpers/Alert';
import Grid from '../Grid';
import DatePicker from 'react-datepicker';
import { smsCount } from '../helpers/utils';
/**
 * display the number of SMS that are sent
 * @returns JSX
 */
const SMSReport = () => {
  const [formValues, setFormValues] = useState({});
  //sets company list options htmlFor select
  const [companyOptions, setCompanyOptions] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [filterBy, setFilterBy] = useState(true);
  const [gridData, setGridData] = useState([]);
  const [gridApi, setGridApi] = useState();

  useEffect(() => {
    companyList();
  }, []);

  useEffect(() => {
    const companyID = formValues['company'];
    const campaignID = formValues['campaign'];
    const startDate = formValues['startDate'];
    const endDate = formValues['endDate'];

    if (companyID) {
      getCampaigns();
    }
    if (companyID && campaignID && filterBy) {
      getSMSStatusByCampaign(campaignID);
    } else if (companyID && startDate && endDate && !filterBy) {
      getSMSStatusByDate(companyID, startDate, endDate);
    }
    // eslint-disable-next-line
    }, [formValues])

  const onGridReady = (params) => {
    setGridApi(params.api);
    //gridColumnApi = params.columnApi;
  };
  /**
   * export the grid into CSX
   */
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };
  // column definitions
  const columnDefs = [
    { headerName: 'SMS ID', field: 'sms_id' },
    { headerName: 'Mobile Number', field: 'mobile_number' },
    { headerName: 'Status', field: 'status' },
    { headerName: 'Order ID', field: 'order_id' },
    {
      headerName: 'SMS Count',
      cellRendererFramework: ({ data }) => {
        return smsCount(data.sms_text);
      }
    },
    { headerName: 'Created At', field: 'created_at' },
    { headerName: 'Updated At', field: 'updated_at' }
  ];
  /**
   * generic function to handle input
   * @param {string} control
   * @param {any} value
   */
  const handleControl = (control, value) => {
    setFormValues({ ...formValues, [control]: value });
  };
  /**
   * gets the payment by campaign
   * @param {number | string} campaignID
   */
  const getSMSStatusByCampaign = async (campaignID) => {
    const token = localStorage.getItem('token');
    const { data } = await axios.get(`/api/sms/status-by-campaign/${campaignID}`, { headers: { authorization: token } });
    console.log('getSMSStatusByCampaign -> data', data);
    setGridData(data);
  };
  /**
   * gets the payment by date
   * @param {number|string} companyID
   * @param {date} startDate
   * @param {date} endDate
   */
  const getSMSStatusByDate = async (companyID, startDate, endDate) => {
    const token = localStorage.getItem('token');
    const { data } = await axios.get(`/api/sms/status-by-date/${companyID}/${startDate}/${endDate}`, { headers: { authorization: token } });
    setGridData(data);
  };
  /**
   * gets the list of campaigns
   */
  const getCampaigns = async () => {
    try {
      const token = localStorage.getItem('token');
      const companyID = formValues['company'];
      if (companyID) {
        const campaigns = await axios.get(`/api/campaign/get-campaign-by-company/${companyID}`, { headers: { authorization: token } });
        // eslint-disable-next-line array-callback-return
        if (_.isObject(campaigns)) {
          // eslint-disable-next-line array-callback-return
          setCampaignOptions([]);
          // eslint-disable-next-line
                    campaigns.data.map(({ campaign_id, campaign_name }) => {
            setCampaignOptions((options) => [...options, { label: campaign_name, value: campaign_id }]);
          });
        } else if (campaigns === 'token_expired') {
          localStorage.setItem('token', 'token_expired');
        }
      }
    } catch (err) {
      console.log('TCL: getCampaigns -> err', err.response);
      const { data } = err.response;
      Alert('error', 'Server Error', data, 'fa-exclamation');
    }
  };
  /**
   * gets the list of company
   */
  const companyList = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/company/company-list', { headers: { authorization: token } });
      const options = [];
      if (_.isArray(data)) {
        data.map(({ company_id, company_name }) => options.push({ label: company_name, value: company_id }));
        setCompanyOptions(options);
      } else if (data === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
      }
    } catch (err) {
      Alert('error', 'Error Occured !!', 'Could not load company list.Please refresh.', 'fas fa-exclamation');
      console.log(err);
    }
  };

  console.log(filterBy);

  return (
    <>
      <style>{`.ag-root-wrapper{width:100%} .react-datepicker{width:auto}`}</style>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Campaign List</h4>
              <div className='form-group'>
                <div className='col-md-9'>
                  <label className='mr-3'>Company</label>
                  <select className='form-control' onChange={(e) => handleControl('company', e.target.value)}>
                    <option value=''>Select company</option>
                    {companyOptions &&
                      companyOptions.map(({ label, value }, index) => {
                        return (
                          <option key={index} value={value}>
                            {label}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className='col-md-9'>
                  <label className='mr-3'>Filter By</label>
                  <div className='form-check form-check-inline d-flex'>
                    <div className='custom-control custom-radio mr-3'>
                      <input
                        id='campaign'
                        type='radio'
                        className='custom-control-input'
                        onChange={() => setFilterBy(true)}
                        checked={filterBy}
                      />
                      <label className='custom-control-label form-control' htmlFor='campaign'>
                        Campaign
                      </label>
                    </div>
                    <div className='custom-control custom-radio mr-3'>
                      <input
                        id='daterange'
                        type='radio'
                        className='custom-control-input'
                        onChange={() => setFilterBy(false)}
                        checked={!filterBy}
                      />
                      <label className='custom-control-label form-control' htmlFor='daterange'>
                        Date Range
                      </label>
                    </div>
                  </div>
                </div>
                {filterBy && (
                  <div className='col-sm-9 mt-3'>
                    <label className='mr-3'>Campaign List</label>
                    <select
                      className='form-control'
                      disabled={_.isEmpty(formValues['company'])}
                      onChange={(e) => handleControl('campaign', e.target.value)}
                      value={formValues['campaign']}
                    >
                      <option value=''>Select campaign</option>
                      {campaignOptions &&
                        campaignOptions.map(({ label, value }, index) => {
                          return (
                            <option key={index} value={value}>
                              {label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                )}
                {!filterBy && (
                  <div className='col-sm-9 mt-3'>
                    <label className='mr-3'>Start Date</label>
                    <DatePicker
                      selected={formValues['startDate']}
                      onChange={(date) => handleControl('startDate', date)}
                      selectsStart
                      startDate={formValues['startDate']}
                      endDate={formValues['endDate']}
                      className='form-control'
                    />
                    <label className='mr-3'>End Date</label>
                    <DatePicker
                      selected={formValues['endDate']}
                      onChange={(date) => handleControl('endDate', date)}
                      selectsEnd
                      startDate={formValues['startDate']}
                      endDate={formValues['endDate']}
                      minDate={formValues['startDate']}
                      className='form-control'
                    />
                  </div>
                )}
              </div>
              <div className='mt-3'>
                <label className='mr-3'>SMS Status</label>
                <button className='btn btn-primary float-right' onClick={() => onBtnExport()}>
                  Export to CSV
                </button>
                <Grid columnDefs={columnDefs} rowData={gridData} onGridReady={onGridReady} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SMSReport;
