import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '../helpers/Alert';
import Grid from '../Grid';
import { format } from 'date-fns';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
/**
 * gives the list of all the mail templates
 * belong to a company.
 * @returns JSX
 */
const MailTemplateList = () => {
  const [gridData, setGridData] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getTemplateList();
    // eslint-disable-next-line
    }, []);

  /**
   * gets the list of all the mail templates
   */
  const getTemplateList = async () => {
    try {
      if (!_.isEmpty(location.state)) {
        const token = localStorage.getItem('token');
        const { company_id } = location.state;
        const { data } = await axios.get(`/api/mailtemplate/template-list/${company_id}`, { headers: { authorization: token } });
        setGridData(data);
      } else {
        Alert('error', 'Internal Error', 'No company associated');
        history.push('/company-list');
      }
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  /**
   * confirmation box to remove mail template
   * @param {number | string} mail_template_id
   */
  const confirmDrop = (mail_template_id) => {
    confirmAlert({
      title: 'Remove Record',
      message: 'Are you sure to remove this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              await axios.post('/api/mailtemplate/delete-template', { mail_template_id }, { headers: { authorization: token } });
              await getTemplateList();
            } catch (err) {
              const { data } = err.response;
              Alert('error', 'Server Error', data);
            }
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };
  // column definitions
  const columnDefs = [
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => history.push('/update-mail-template', data)}>
            <i className='fas fa-pencil-alt text-success' title='Edit entry'></i>
          </a>
        );
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => confirmDrop(data.mail_template_id)}>
            <i className='far fa-trash-alt text-danger' title='Delete entry'></i>
          </a>
        );
      }
    },
    { field: 'mail_template_id', hide: true },
    { headerName: 'Template Name', field: 'template_name', filter: true },
    { headerName: 'Template HTML', field: 'template_html', filter: true },
    { headerName: 'Company Name', field: 'company_name', filter: true },
    {
      headerName: 'Created Date',
      field: 'created_at',
      filter: true,
      cellRendererFramework: ({ value }) => {
        return (value && format(new Date(value), 'yyyy-MM-dd HH:mm:ss')) || '';
      }
    },
    {
      headerName: 'Updated Date',
      field: 'updated_at',
      filter: true,
      cellRendererFramework: ({ value }) => {
        return (value && format(new Date(value), 'yyyy-MM-dd HH:mm:ss')) || '';
      }
    }
  ];

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Template List</h4>
              <Grid columnDefs={columnDefs} rowData={gridData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailTemplateList;
