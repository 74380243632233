import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '../helpers/Alert';
import Grid from '../Grid';
import { format } from 'date-fns';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
/**
 * Displays the list of emails belong to company
 * @returns JSX
 */
const EmailList = () => {
  const [gridData, setGridData] = useState([]);
  const history = useHistory();
  const location = useLocation();
  // gets email list
  useEffect(() => {
    getEmailList();
    // eslint-disable-next-line
    }, []);
  /**
   * gets email list
   */
  const getEmailList = async () => {
    try {
      if (!_.isEmpty(location.state)) {
        const token = localStorage.getItem('token');
        const { company_id } = location.state;
        const { data } = await axios.get(`/api/mailprovider/email-list/${company_id}`, { headers: { authorization: token } });
        setGridData(data);
      } else {
        Alert('error', 'Internal Error', 'No company associated');
        history.push('/company-list');
      }
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };
  /**
   * confirmation box to remove email
   * @param  {number|string} company_email_id
   */
  const confirmDrop = (company_email_id) => {
    confirmAlert({
      title: 'Remove Record',
      message: 'Are you sure to remove this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              await axios.post('/api/mailprovider/delete-mail', { company_email_id }, { headers: { authorization: token } });
              await getEmailList();
            } catch (err) {
              const { data } = err.response;
              Alert('error', 'Server Error', data);
            }
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };
  //grid columns
  const columnDefs = [
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => history.push('/update-mail', data)}>
            <i className='fas fa-pencil-alt text-success' title='Edit entry'></i>
          </a>
        );
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a href='# ' onClick={() => confirmDrop(data.company_email_id)}>
            <i className='far fa-trash-alt text-danger' title='Delete entry'></i>
          </a>
        );
      }
    },
    { field: 'payment_config_id', hide: true },
    { headerName: 'Mail ID', field: 'company_email_id', filter: true },
    { headerName: 'Email', field: 'email', filter: true },
    { headerName: 'Company Name', field: 'company_name', filter: true },
    {
      headerName: 'Created Date',
      field: 'created_at',
      filter: true,
      cellRendererFramework: ({ value }) => {
        return (value && format(new Date(value), 'yyyy-MM-dd HH:mm:ss')) || '';
      }
    },
    {
      headerName: 'Updated Date',
      field: 'updated_at',
      filter: true,
      cellRendererFramework: ({ value }) => {
        return (value && format(new Date(value), 'yyyy-MM-dd HH:mm:ss')) || '';
      }
    }
  ];

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Email List</h4>
              <Grid columnDefs={columnDefs} rowData={gridData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailList;
