import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import Alert from '../helpers/Alert';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
/**
 * updates the mail providers
 * @returns JSX
 */
const UpdateMailProviders = () => {
  const { register, errors, setValue, getValues, handleSubmit } = useForm();
  const [formValues, setFormValues] = useState({});
  const [providerTypesOptions, setProviderTypesOptions] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const { mail_provider_id, mail_provider_name, mail_provider_type_id } = location.state;
      //fetches the fields from API and restore them for edit
      const restoreFileds = { mail_provider_id, mail_provider_name, mail_provider_type_id };
      setFormValues({ ...formValues, ...restoreFileds });
      _.mapValues(restoreFileds, (value, key) => {
        setValue(key, value);
      });
      getProviderTypes();
    } else {
      Alert('error', 'Internal Error', 'Could not load update values. Seems you tried to access this page directly.');
      history.push('/providers');
    }
  }, [formValues, history, location.state, setValue]);
  /**
   * gets the provider types
   */
  const getProviderTypes = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/mailprovider/provider-types', { headers: { authorization: token } });
      setProviderTypesOptions(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };
  /**
   * generic function to handle input
   * @param {string} control
   * @param {any} value
   */
  const handleControl = (control, value) => {
    setValue(control, value);
    setFormValues({ ...formValues, ...getValues() });
  };
  /**
   * form submit
   */
  const handleFormSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/mailprovider/update-provider', formValues, { headers: { authorization: token } });
      Alert('success', 'Provider Update', 'Provider updated successfully');
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Update Connection</h4>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Provider Type</label>
                    <select
                      name='mail_provider_type_id'
                      value={formValues['mail_provider_type_id'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('mail_provider_type_id', e.target.value)}
                      ref={register({ name: 'mail_provider_type_id' }, { required: { value: true, message: 'Field is required.' } })}
                    >
                      <option value=''>Select Type</option>
                      {providerTypesOptions.map(({ mail_provider_type_id, mail_provider_name }, idx) => {
                        return (
                          <option key={idx} value={mail_provider_type_id}>
                            {mail_provider_name}
                          </option>
                        );
                      })}
                    </select>
                    <p className='error-text'>{errors.mail_provider_type_id && errors.mail_provider_type_id.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>Provider Name</label>
                    <input
                      name='mail_provider_name'
                      value={formValues['mail_provider_name'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('mail_provider_name', e.target.value)}
                      ref={register({ name: 'mail_provider_name' }, { required: { value: true, message: 'Field is required.' } })}
                    ></input>
                    <p className='error-text'>{errors.mail_provider_name && errors.mail_provider_name.message}</p>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-md-9'>
                    <label className='mr-3'>APIKey</label>
                    <input
                      name='api_key'
                      type='text'
                      value={formValues['api_key'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('api_key', e.target.value)}
                      ref={register({ name: 'api_key' }, { required: { value: true, message: 'Field is required.' } })}
                    ></input>
                    <p className='error-text'>{errors.api_key && errors.api_key.message}</p>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary float-right'>
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateMailProviders;
