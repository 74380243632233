//
// ────────────────────────────────────────────────────────────────────
//   :::::: E N C R Y P T E R : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────
// Use for enconding decoding purposes.
// It's used to decrypt the Roles of users login
// which are now extended into separate project.

const crypto = require('crypto');
const ENC_KEY = 'bf3c199c2470cb477d907b1e0917c17b'; // set random encryption key
const IV = '5183666c72eec9e4'; // set random initialization vector
// ENC_KEY and IV can be generated as crypto.randomBytes(32).toString('hex');

/**
 * encryps text given to function
 * @param  {string} text - text to encrypt
 * @returns string
 */
var encrypt = (text) => {
  let cipher = crypto.createCipheriv('aes-256-cbc', ENC_KEY, IV);
  let encrypted = cipher.update(text.toString(), 'utf8', 'base64');
  encrypted += cipher.final('base64');
  return encrypted;
};
/**
 * decrypts text given to function
 * @param  {string} text - text to decrypt
 * @returns string
 */
var decrypt = (text) => {
  let decipher = crypto.createDecipheriv('aes-256-cbc', ENC_KEY, IV);
  let decrypted = decipher.update(text.toString(), 'base64', 'utf8');
  return decrypted + decipher.final('utf8');
};

module.exports = { encrypt, decrypt };
