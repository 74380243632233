import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Form from 'react-basic-form';
import { format } from 'date-fns';
import Alert from '../helpers/Alert';

//this component used for both create and update company
var countries = require('country-json/src/country-by-name.json');
/**
 * gets JSON list of countries
 */
const getCountries = () => {
  const countriesList = [];
  countries.map(({ country }) => {
    countriesList.push({ value: country, label: country });
    return true;
  });

  return countriesList;
};
/**
 * handler to submit form, handles
 * edit and create company
 * @param  {object} data
 * @param  {bool} isEdit
 */
const handleSubmit = async (data, isEdit) => {
  try {
    const token = localStorage.getItem('token');
    //if created will use created date else will use update_date
    isEdit
      ? (data['updated_at'] = format(Date.now(), 'yyyy-MM-dd HH:mm:ss'))
      : (data['created_at'] = format(Date.now(), 'yyyy-MM-dd HH:mm:ss'));
    //post as edit or insert
    let res = undefined;
    if (isEdit) {
      res = await axios.put(
        '/api/company/update-company',
        { data },
        { headers: { 'Content-Type': 'application/json', authorization: token } }
      );
    } else {
      res = await axios.post(
        '/api/company/add-company',
        { data },
        { headers: { 'Content-Type': 'application/json', authorization: token } }
      );
    }

    if (res === 'token_expired') {
      localStorage.setItem('token', 'token_expired');
      Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
    } else {
      Alert('success', 'Operation Successfull', `Record ${isEdit ? 'updated' : 'inserted'} successfull.`, 'fa-check-circle');
    }
  } catch (err) {
    Alert('error', 'Error Occured !!', 'Error occured while inserting record.', 'fas fa-exclamation');
    console.log(err);
  }
};
/**
 * creates new company
 * @param  {any} props
 */
const CreateCompany = (props) => {
  //simple state to save values of form
  const [formValues, setFormValues] = useState();
  //check if the mode is edit or create
  const isEdit = useRef(false);
  //saves companyID incase of edit/update
  const companyID = useRef(undefined);

  //checks if props are updated and monitor if
  //data is passed for edit
  useEffect(() => {
    if (props.location) {
      const formValues = props.location && props.location.data;
      if (formValues) {
        const { data } = formValues;
        isEdit.current = true;
        companyID.current = data.company_id;
        setFormValues(data);
      }
    }
  }, [props.location]);

  //single change event for all controls
  const handleControl = (formControl, value) => {
    setFormValues({ ...formValues, [formControl]: value });
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <Form
              onSubmit={(data) => handleSubmit(data, isEdit.current)}
              validations={{
                email: (value) => /\S+@\S+\.\S+/.test(value)
              }}
              errorMessages={{
                email: 'Please check your email address.'
              }}
            >
              <div className='card-body'>
                <h4 className='card-title'>{isEdit.current ? 'Edit Company' : 'Create Company'}</h4>
                <hr />
                <div className='form-group'>
                  <Form.Element name='company_id' type='hidden' value={companyID.current} />
                  <Form.Element
                    name='company_name'
                    className='form-control'
                    onChange={(e) => handleControl('company_name', e.target.value)}
                    value={(formValues && formValues.company_name) || ''}
                    label='Company Name'
                    type='text'
                    required
                  />
                  <Form.Element
                    name='address'
                    className='form-control'
                    onChange={(e) => handleControl('address', e.target.value)}
                    value={(formValues && formValues.address) || ''}
                    label='Address'
                    type='text'
                    required
                  />
                  <Form.Element
                    name='zip_code'
                    className='form-control'
                    onChange={(e) => handleControl('zip_code', e.target.value)}
                    value={(formValues && formValues.zip_code) || ''}
                    label='Zip Code'
                    type='text'
                    required
                  />
                  <Form.Element
                    name='city'
                    className='form-control'
                    onChange={(e) => handleControl('city', e.target.value)}
                    value={(formValues && formValues.city) || ''}
                    label='City'
                    type='text'
                    required
                  />
                  <Form.Element
                    name='country'
                    className='form-control form-control'
                    onChange={(e) => handleControl('country', e.target.value)}
                    type='select'
                    label='Country'
                    value={formValues && formValues.country}
                    options={getCountries()}
                    required
                  />
                  <Form.Element
                    name='email'
                    className='form-control'
                    onChange={(e) => handleControl('email', e.target.value)}
                    value={(formValues && formValues.email) || ''}
                    label='Email'
                    type='text'
                    required
                  />
                  <Form.Element
                    name='first_name'
                    className='form-control'
                    onChange={(e) => handleControl('first_name', e.target.value)}
                    value={(formValues && formValues.first_name) || ''}
                    label='First Name'
                    type='text'
                  />
                  <Form.Element
                    name='sur_name'
                    className='form-control'
                    onChange={(e) => handleControl('sur_name', e.target.value)}
                    value={(formValues && formValues.sur_name) || ''}
                    label='Sur Name'
                    type='text'
                  />
                  <Form.Element
                    name='contact_number'
                    className='form-control'
                    onChange={(e) => handleControl('contact_number', e.target.value)}
                    value={(formValues && formValues.contact_number) || ''}
                    label='Contact Number'
                    type='text'
                  />
                  <Form.Element
                    name='vat_number'
                    className='form-control'
                    onChange={(e) => handleControl('vat_number', e.target.value)}
                    value={(formValues && formValues.vat_number) || ''}
                    label='VAT Number'
                    type='text'
                  />
                  <Form.Element
                    name='iban_number'
                    className='form-control'
                    onChange={(e) => handleControl('iban_number', e.target.value)}
                    value={(formValues && formValues.iban_number) || ''}
                    label='IBAN Number'
                    type='text'
                  />
                  <Form.Element
                    name='bcc_number'
                    className='form-control'
                    onChange={(e) => handleControl('bcc_number', e.target.value)}
                    value={(formValues && formValues.bcc_number) || ''}
                    label='BCC Number'
                    type='text'
                  />
                </div>
                <Form.Submit className='btn btn-primary float-right' text='Submit' />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCompany;
