import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Grid from '../Grid';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import Alert from '../helpers/Alert';
import Confirm from '../helpers/Confirm';

const MailProviders = () => {
  const [gridData, setGridData] = useState([]);
  const [providerTypes, setProviderTypes] = useState([]);
  const history = useHistory();
  const providerTypeRef = useRef();

  useEffect(() => {
    getProviders();
    getProviderTypes();
  }, []);

  useEffect(() => {
    providerTypeRef.current = providerTypes;
  }, [providerTypes]);

  const columnDefs = [
    { headerName: 'Provider ID', field: 'mail_provider_id' },
    { headerName: 'Provider Name', field: 'mail_provider_name' },
    {
      headerName: 'Provider Type',
      field: 'mail_provider_type_id',
      cellRendererFramework: ({ value }) => {
        const providerType = providerTypeRef.current;
        if (providerType.length > 0) {
          const providerName = value ? providerType[value - 1]['mail_provider_name'] : 'N/A';
          return providerName;
        }
        return 'N/A';
      }
    },
    {
      headerName: 'Created Date',
      field: 'created_at',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },
    {
      headerName: 'Updated Date',
      field: 'updated_at',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        /*eslint-disable-next-line*/
                return (<a href="#" onClick={() => history.push('/update-mail-providers', data)} role="button" aria-disabled="true"><i className="fas fa-pencil-alt text-success" disabled={true} title="Start SMS"></i></a>);
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        /*eslint-disable-next-line*/
                return <a href="# " onClick={() => confirm(data.mail_provider_id)}><i className="far fa-trash-alt text-danger" title="Delete entry"></i></a>;
      }
    }
  ];

  const confirm = (mail_provider_id) => {
    Confirm('Delete Provider', 'Do you want to delete this provider.', async () => {
      try {
        const token = localStorage.getItem('token');
        await axios.post('/api/mailprovider/delete', { mail_provider_id }, { headers: { authorization: token } });
        Alert('success', 'Provider Deleted', 'Provider successfully deleted.', 'fa-check-circle');
        getProviders();
      } catch (err) {
        const { data } = err.response;
        Alert('error', 'Server Error', data);
      }
    });
  };

  const getProviders = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/mailprovider/provider-list', { headers: { authorization: token } });
      setGridData(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  const getProviderTypes = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/mailprovider/provider-types', { headers: { authorization: token } });
      setProviderTypes(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Connections</h4>
              <Grid columnDefs={columnDefs} rowData={gridData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailProviders;
