import React, { useState, useEffect } from 'react';
import useForm from 'react-hook-form';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Alert from '../helpers/Alert';
/**
 * updates the email component is visually
 * similar to create email instead it does update
 * @returns JSX
 */
const UpdateEmail = () => {
  const { register, errors, setValue, getValues, handleSubmit } = useForm();
  const [formValues, setFormValues] = useState([]);

  const [companyParams, setCompanyParams] = useState({});

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      const companyParams = { ...location.state };
      const { company_email_id, email } = location.state;
      const restoreFileds = { company_email_id, email };
      setFormValues({ ...formValues, ...restoreFileds });
      setCompanyParams(companyParams);
      _.mapValues(restoreFileds, (value, key) => {
        setValue(key, value);
      });
    } else {
      Alert('error', 'Internal Error', "Could't find associated company.");
      history.push('/company-list');
    }
    // eslint-disable-next-line
    }, [])

  /**
   * generic function to handle
   * inputs
   * @param {string} control
   * @param {any} value
   */
  const handleControl = (control, value) => {
    setValue(control, value);
    setFormValues({ ...formValues, ...getValues() });
  };
  /**
   * handles form submit
   */
  const handleFormSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('/api/mailprovider/update-email', formValues, { headers: { authorization: token } });
      Alert('success', 'Email Created', 'Email created successfully');
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Create Email @{companyParams.company_name}</h4>
              <form className='form-inline' onSubmit={handleSubmit(handleFormSubmit)}>
                <div className='form-group'>
                  <div className='col-md-10'>
                    <label className='mr-3 d-block'>Update Email</label>
                    <input
                      name='email'
                      value={formValues['email'] || ''}
                      className='form-control  form-control-lg'
                      onChange={(e) => handleControl('email', e.target.value)}
                      ref={register(
                        { name: 'email' },
                        {
                          required: { value: true, message: 'Field is required.' },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'invalid email address'
                          }
                        }
                      )}
                    ></input>
                    <p className='error-text'>{errors.email && errors.email.message}</p>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary'>
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateEmail;
