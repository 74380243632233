import { confirmAlert } from 'react-confirm-alert'; // Import
/**
 * displays confirmation box
 * @param {string} title
 * @param {string} message
 * @param {function} cb
 */
const Confirm = (title, message, cb) => {
  confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: 'Yes',
        onClick: () => cb()
      },
      {
        label: 'No'
      }
    ]
  });
};

export default Confirm;
