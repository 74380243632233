import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
/**
 * its shows the account status of the provider
 * accounts
 * @returns JSX
 */
const Status = () => {
  const [skebbyStatus, setSkebbyStatus] = useState([]);
  const [arubaStatus, setArubaStatus] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [kaleyraStatus, setKaleyraStatus] = useState([]);
  const [credentials, setcredentials] = useState();

  useEffect(() => {
    getProviderCreds();
  }, []);

  useEffect(() => {
    getSkebbyStatus();
    getArubaStatus();
    getKaleyraStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);
  /**
   * gets the providers credentials
   */
  const getProviderCreds = async () => {
    const token = localStorage.getItem('token');
    const { data } = await axios.get('/api/provider/provider-creds', { headers: { authorization: token } });
    setcredentials(data);
  };
  /**
   * gets the kaleyra account status
   */
  const getKaleyraStatus = async () => {
    if (credentials) {
      const creds = credentials.filter((cred) => cred.provider_type_id === 2);
      const res = await Promise.all(
        creds.map(async ({ username, password }) => {
          const { data } = await axios.post('/api/sms/kaleyra/status', { username, password });
          return data;
        })
      );

      setKaleyraStatus(res);
      console.log('getKaleyraStatus -> res', res);
    }
  };
  /**
   * gets skebby account status
   */
  const getSkebbyStatus = async () => {
    if (credentials) {
      const creds = credentials.filter((cred) => cred.provider_type_id === 1);
      const res = await Promise.all(
        creds.map(async ({ username, password }) => {
          const { data } = await axios.post('/api/sms/skebby/status', { username, password });
          return data;
        })
      );

      setSkebbyStatus(res);
    }
  };
  /**
   * gets aruba account status
   */
  const getArubaStatus = async () => {
    if (credentials) {
      const creds = credentials.filter((cred) => cred.provider_type_id === 5);
      const res = await Promise.all(
        creds.map(async ({ username, password }) => {
          const { data } = await axios.post('/api/sms/aruba/status', { username, password });
          const resRefine = data.split(';');
          const dataRefined = { local: resRefine[1].replace(/\D+/, ''), international: resRefine[2].replace(/\D+/, '') };
          return dataRefined;
        })
      );

      setArubaStatus(res);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Providers Status</h4>
              <div className='row'>
                {skebbyStatus.length > 0 &&
                  skebbyStatus.map((data, index) => (
                    <div className='col-sm-5' key={index}>
                      <div className='card card-hover'>
                        <div className='box bg-success text-center'>
                          <a href='/sms-reporting'>
                            <h1 className='font-light text-white'>
                              <i className='mdi mdi-message-text-outline'></i>
                            </h1>
                            <h6 className='text-white'>skebby</h6>
                            <ul className='list-group'>
                              <li className='list-group-item d-flex justify-content-between align-items-center'>
                                Money
                                <span className='badge badge-primary badge-pill'>{data.money}</span>
                              </li>
                              {data.sms.map(({ type, quantity }, index) => (
                                <li key={index} className='list-group-item d-flex justify-content-between align-items-center'>
                                  {type}
                                  <span className='badge badge-primary badge-pill'>{quantity}</span>
                                </li>
                              ))}
                            </ul>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}

                <div className='col-sm-3'>
                  <div className='card card-hover'>
                    <div className='box bg-primary text-center'>
                      <a href='/payment-report'>
                        <h1 className='font-light text-white'>
                          <i className='mdi mdi-message-text-outline'></i>
                        </h1>
                        <h6 className='text-white'>Kaleyra</h6>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3'>
                  <div className='card card-hover'>
                    <div className='box bg-danger text-center'>
                      <a href='/payment-report'>
                        <h1 className='font-light text-white'>
                          <i className='mdi mdi-message-text-outline'></i>
                        </h1>
                        <h6 className='text-white'>Aimon</h6>
                      </a>
                    </div>
                  </div>
                </div>
                {arubaStatus.length > 0 &&
                  arubaStatus.map((data, index) => (
                    <div className='col-sm-5' key={index}>
                      <div className='card card-hover'>
                        <div className='box bg-info text-center'>
                          <a href='/sms-reporting'>
                            <h1 className='font-light text-white'>
                              <i className='mdi mdi-message-text-outline'></i>
                            </h1>
                            <h6 className='text-white'>aruba</h6>
                            <ul className='list-group'>
                              {_.map(data, (value, key) => (
                                <li key={key} className='list-group-item d-flex justify-content-between align-items-center'>
                                  {key}
                                  <span className='badge badge-primary badge-pill'>{value}</span>
                                </li>
                              ))}
                            </ul>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Status;
