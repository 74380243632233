import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Grid from '../Grid';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import Alert from '../helpers/Alert';
import Confirm from '../helpers/Confirm';
/**
 * displays the list of available providers
 * @returns JSX
 */
const Providers = () => {
  const [gridData, setGridData] = useState([]);
  const [providerTypes, setProviderTypes] = useState([]);
  const history = useHistory();
  const providerTypeRef = useRef();

  useEffect(() => {
    getProviders();
    getProviderTypes();
  }, []);

  useEffect(() => {
    providerTypeRef.current = providerTypes;
  }, [providerTypes]);
  // column definations
  const columnDefs = [
    { headerName: 'Provider ID', field: 'provider_id' },
    { headerName: 'Provider Name', field: 'provider_name' },
    { headerName: 'Username', field: 'username' },
    {
      headerName: 'Provider Type',
      field: 'provider_type_id',
      cellRendererFramework: ({ value }) => {
        const providerType = providerTypeRef.current;
        if (providerType.length > 0) {
          const providerName = value ? providerType[value - 1]['provider_name'] : 'N/A';
          return providerName;
        }
        return 'N/A';
      }
    },
    {
      headerName: 'Created Date',
      field: 'created_at',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },
    {
      headerName: 'Updated Date',
      field: 'updated_at',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        /*eslint-disable-next-line*/
                return <a href="#" onClick={() => history.push('/update-providers', data)} role="button" aria-disabled="true"><i className="fas fa-pencil-alt text-success" disabled={true} title="Start SMS"></i></a>;
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        /*eslint-disable-next-line*/
                return <a href="# " onClick={() => confirm(data.provider_id)}><i className="far fa-trash-alt text-danger" title="Delete entry"></i></a>;
      }
    }
  ];
  /**
   * confirmation box to remove provider
   * @param {string|number} provider_id
   */
  const confirm = (provider_id) => {
    Confirm('Delete Provider', 'Do you want to delete this provider.', async () => {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/api/provider/delete/${provider_id}`, { headers: { authorization: token } });
        Alert('success', 'Provider Deleted', 'Provider successfully deleted.', 'fa-check-circle');
        getProviders();
      } catch (err) {
        const { data } = err.response;
        Alert('error', 'Server Error', data);
      }
    });
  };
  /**
   * gets list of providers
   */
  const getProviders = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/provider/provider-list', { headers: { authorization: token } });
      setGridData(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };
  /**
   * gets list of providers type
   */
  const getProviderTypes = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/provider/provider-types', { headers: { authorization: token } });
      setProviderTypes(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Connections</h4>
              <Grid columnDefs={columnDefs} rowData={gridData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Providers;
