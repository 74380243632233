//
// ────────────────────────────────────────────────────────────────────────
//   :::::: M E S S A G E B A S E : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────

import React from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import TextInput from 'react-autocomplete-input';
/**
 * Reusable component Renders inputs for message campaign configuration
 * its an child component to createCampaign
 * @param  {function} handleControl
 * @param  {object} formValues
 * @param  {function} register
 * @param  {object} errors
 * @param  {array} aliasOptions
 * @param  {bool} mode
 * @param  {function} setMode
 * @param  {bool} showExpiry
 * @param  {object} expiryMode
 * @param  {function} handleRadio
 * @param  {bool} type
 * @param  {array} templateOptions
 * @param  {array} providerOptions}
 */
const MessageBase = ({
  handleControl,
  formValues,
  register,
  errors,
  aliasOptions,
  mode,
  setMode,
  showExpiry,
  expiryMode,
  handleRadio,
  type,
  templateOptions,
  providerOptions
}) => {
  return (
    <>
      <div className='form-group'>
        <div className='col-md-9'>
          <label className='mr-3'>Provider Name</label>
          <select
            className='form-control col-sm-11 d-inline mr-1'
            name='provider_id'
            disabled={_.isEmpty(providerOptions)}
            onChange={(e) => handleControl('provider_id', e.target.value)}
            value={formValues['provider_id']}
            ref={register({ name: 'provider_id' }, { required: { value: true, message: 'Field is required.' } })}
          >
            <option>Select Provider</option>
            {providerOptions.map(({ label, value }, index) => {
              return (
                <option key={index} value={value}>
                  {label}
                </option>
              );
            })}
          </select>
          {_.isEmpty(providerOptions) && (
            <span className='input-group-addon'>
              <i className='fa fa-refresh fa-spin'></i>
            </span>
          )}
          <p className='error-text'>{errors.provider_id && errors.provider_id.message}</p>
        </div>
      </div>

      <div className='form-group'>
        <div className='col-md-9'>
          <label className='mr-3'>Sender</label>
          <select
            className='form-control col-sm-11 d-inline mr-1'
            name='sender'
            onChange={(e) => handleControl('sender', e.target.value)}
            disabled={_.isEmpty(aliasOptions)}
            value={formValues['sender']}
            ref={register({ name: 'sender' }, { required: { value: true, message: 'Field is required.' } })}
          >
            <option>Select Sender</option>
            {aliasOptions.map((value, index) => {
              return (
                <option key={index} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
          {_.isEmpty(aliasOptions) && !_.isEmpty(formValues['provider_id']) && (
            <span className='input-group-addon'>
              <i className='fa fa-refresh fa-spin'></i>
            </span>
          )}
          <p className='error-text'>{errors.sender && errors.sender.message}</p>
        </div>
      </div>

      <label className='form-control'>Mode</label>
      <div className='form-check form-check-inline d-flex'>
        <div className='custom-control custom-radio mr-3'>
          <input
            type='radio'
            className='custom-control-input'
            checked={mode['realtime']}
            id='realtime'
            value={0}
            name='mode'
            onChange={(e) => {
              setMode({ realtime: true, batch: false });
              delete formValues['schedule_time'];
              handleControl('mode', e.target.value);
            }}
          />
          <label className='custom-control-label form-control' htmlFor='realtime'>
            Real-time
          </label>
        </div>
        <div className='custom-control custom-radio mr-3'>
          <input
            type='radio'
            className='custom-control-input'
            checked={mode['batch']}
            id='batch'
            name='mode'
            value={1}
            onChange={(e) => {
              setMode({ realtime: false, batch: true });
              handleControl('mode', e.target.value);
            }}
          />
          <label className='custom-control-label form-control' htmlFor='batch'>
            Batch
          </label>
        </div>
      </div>

      {showExpiry && (
        <>
          <label className='form-control'>Expiry Mode</label>
          <div className='form-check form-check-inline d-flex'>
            <div className='custom-control custom-radio mr-3'>
              <input
                type='radio'
                className='custom-control-input'
                value={0}
                checked={expiryMode['visited']}
                onChange={(e) => {
                  handleRadio('visited', e.target.value);
                }}
                id='visited'
                name='expiryMode'
              />
              <label className='custom-control-label form-control' htmlFor='visited'>
                Visited
              </label>
            </div>
            <div className='custom-control custom-radio mr-3'>
              <input
                type='radio'
                className='custom-control-input form-control'
                id='tries'
                value={1}
                checked={expiryMode['tries']}
                onChange={(e) => {
                  handleRadio('tries', e.target.value);
                }}
                name='expiryMode'
              />
              <label className='custom-control-label form-control' htmlFor='tries'>
                Tries
              </label>
            </div>
            <div className='custom-control custom-radio mr-3'>
              <input
                type='radio'
                className='custom-control-input'
                value={2}
                checked={expiryMode['noexpiry']}
                onChange={(e) => {
                  handleRadio('noexpiry', e.target.value);
                }}
                id='noexpiry'
                name='expiryMode'
              />
              <label className='custom-control-label form-control' htmlFor='noexpiry'>
                No Expiry
              </label>
            </div>
          </div>
          {expiryMode['tries'] || expiryMode['noexpiry'] ? (
            <>
              <div className='form-group'>
                <div className='col-md-9'>
                  <label className='form-control'>Max Tries</label>
                  <input
                    name='max_tries'
                    className='form-control'
                    onChange={(e) => handleControl('max_tries', e.target.value)}
                    value={(formValues && formValues['max_tries']) || 0}
                    type='number'
                    ref={register({ name: 'max_tries' }, { required: { value: true, message: 'Field is required.' } })}
                  />
                  <p className='error-text'>{errors.max_tries && errors.max_tries.message}</p>
                </div>
              </div>
            </>
          ) : null}
          {!expiryMode['noexpiry'] ? (
            <div className='form-group'>
              <div className='col-md-9'>
                <label>Duration (hours)</label>
                <input
                  name='duration'
                  className='form-control'
                  onChange={(e) => handleControl('duration', e.target.value)}
                  value={(formValues && formValues['duration']) || 72}
                  type='number'
                  ref={register({ name: 'duration' }, { required: { value: true, message: 'Field is required.' } })}
                />
                <p className='error-text'>{errors.duration && errors.duration.message}</p>
              </div>
            </div>
          ) : null}
          {mode['batch'] ? (
            <>
              <label className='custom-control-label form-control' htmlFor='batch'>
                Date/Time
              </label>
              <DatePicker
                name='schedule_time'
                showTimeSelect
                timeFormat='HH:mm'
                dateFormat='yyyy-MM-dd HH:mm'
                timeIntervals={5}
                timeCaption='Time'
                selected={formValues['schedule_time'] || undefined}
                onChange={(date) => handleControl('schedule_time', date)}
                className='form-control'
                ref={register({ name: 'schedule_time' }, { required: { value: true, message: 'Field is required.' } })}
              />
              <p className='error-text'>{errors.schedule_time && errors.schedule_time.message}</p>
            </>
          ) : null}
        </>
      )}
      <label className='form-control'>Message Type</label>
      <div className='form-check form-check-inline d-flex'>
        <div className='custom-control custom-radio mr-3'>
          <input
            type='radio'
            className='custom-control-input'
            value={0}
            checked={type['visual']}
            onChange={(e) => {
              handleRadio('visual', e.target.value);
            }}
            id='visual'
            name='type'
          />
          <label className='custom-control-label form-control' htmlFor='visual'>
            Visual
          </label>
        </div>
        <div className='custom-control custom-radio mr-3'>
          <input
            type='radio'
            className='custom-control-input form-control'
            id='simple'
            value={1}
            checked={type['simple']}
            onChange={(e) => {
              handleRadio('simple', e.target.value);
            }}
            name='type'
          />
          <label className='custom-control-label form-control' htmlFor='simple'>
            Simple
          </label>
        </div>
        <div className='custom-control custom-radio mr-3'>
          <input
            type='radio'
            className='custom-control-input form-control'
            id='template'
            value={2}
            checked={type['template'] || 0}
            onChange={(e) => {
              handleRadio('template', e.target.value);
            }}
            name='type'
          />
          <label className='custom-control-label form-control' htmlFor='template'>
            Template
          </label>
        </div>
      </div>
      {type['simple'] ? (
        <div className='form-group'>
          <div className='col-md-9'>
            <label>Message</label>
            <textarea
              className='form-control'
              name='sms_text'
              onChange={(e) => handleControl('sms_text', e.target.value)}
              value={(formValues && formValues['sms_text']) || ''}
              ref={register({ name: 'sms_text' }, { required: { value: true, message: 'Field is required.' } })}
            />
            <p className='error-text'>{errors.sms_text && errors.sms_text.message}</p>
            <p className='text-right'>{formValues && formValues['sms_text'] && `${formValues['sms_text'].length} chars`}</p>
          </div>
        </div>
      ) : type['visual'] ? (
        <>
          <div className='form-group'>
            <div className='col-md-9'>
              <label>SMS Head</label>
              <textarea
                className='form-control'
                name='sms_head'
                onChange={(e) => handleControl('sms_head', e.target.value)}
                value={(formValues && formValues['sms_head']) || ''}
                ref={register({ name: 'sms_head' }, { required: { value: true, message: 'Field is required.' } })}
              />
              <p className='error-text'>{errors.sms_head && errors.sms_head.message}</p>
              <p className='text-right'>{formValues && formValues['sms_head'] && `${formValues['sms_head'].length} chars`}</p>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-md-9'>
              <label>SMS Tail</label>
              <textarea
                className='form-control'
                name='sms_tail'
                onChange={(e) => handleControl('sms_tail', e.target.value)}
                value={(formValues && formValues['sms_tail']) || ''}
                ref={register({ name: 'sms_tail' }, { required: { value: true, message: 'Field is required.' } })}
              />
              <p className='error-text'>{errors.sms_tail && errors.sms_tail.message}</p>
              <p className='text-right'>{formValues && formValues['sms_tail'] && `${formValues['sms_tail'].length} chars`}</p>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-md-9'>
              <label>URL</label>
              <input
                className='form-control'
                name='sms_url'
                onChange={(e) => handleControl('sms_url', e.target.value)}
                value={(formValues && formValues['sms_url']) || ''}
                ref={register({ name: 'sms_url' }, { required: { value: true, message: 'Field is required.' } })}
              />
              <p className='error-text'>{errors.sms_url && errors.sms_url.message}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='form-group'>
            <div className='col-md-9'>
              <label>Compose Template</label>
              <TextInput
                name='template'
                className='form-control'
                options={templateOptions}
                onChange={(value) => handleControl('template', value)}
                value={(formValues && formValues['template']) || ''}
                ref={register(
                  { name: 'template' },
                  {
                    required: { value: true, message: 'Field is required.' },
                    pattern: { value: /@[A-Z]+/gm, message: 'no template string found' }
                  }
                )}
              />
              <p className='error-text'>{errors.template && errors.template.message}</p>
              <p className='text-right'>{formValues && formValues['template'] && `${formValues['template'].length} chars`}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MessageBase;
