import React, { useState, useEffect } from 'react';
import Grid from '../Grid';
import axios from 'axios';
import Alert from '../helpers/Alert';
import Confirm from '../helpers/Confirm';
import { format } from 'date-fns';
/**
 * gets the list of saved local alias in the db
 * in this providers do not provide API for alias
 * @param {any} props
 * @returns JSX
 */
const ShowGeneralAlias = (props) => {
  const { provider_id } = props;

  const [gridData, setGridData] = useState([]);

  useEffect(() => {
    if (provider_id) {
      getAlias();
    }
    // eslint-disable-next-line
    }, [provider_id]);
  /**
   * get's the list of alias from db
   */
  const getAlias = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`/api/provider/get-alias-general-all/${provider_id}`, { headers: { authorization: token } });
      console.log('getAlias -> data', data);
      setGridData(data);
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Server Error', data);
    }
  };
  // column defination
  const columnDefs = [
    { headerName: 'Alias ID', field: 'alias_id' },
    { headerName: 'Alias', field: 'alias' },
    {
      headerName: 'Created At',
      field: 'created_at',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },

    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        /*eslint-disable-next-line*/
                return <a href="# " onClick={() => confirm(data['alias_id'])}><i className="far fa-trash-alt text-danger" title="Delete entry"></i></a>;
      }
    }
  ];

  const confirm = (aliasID) => {
    Confirm('Remove Alias', 'Do you want to delete this alias.', async () => {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/api/provider/remove-alias-general/${aliasID}`, { headers: { authorization: token } });
        Alert('success', 'Alias Deleted', 'Alias successfully deleted.', 'fa-check-circle');
        getAlias();
      } catch (err) {
        const { data } = err.response;
        Alert('error', 'Server Error', data);
      }
    });
  };

  return (
    <div>
      <Grid columnDefs={columnDefs} rowData={gridData} />
    </div>
  );
};

export default ShowGeneralAlias;
