import React from 'react'


const Response = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    const error = urlParams.get('error');
    return (
        <>
            <style>
                {
                    `
                    body {
                        background-color:#3e5569
                    }
                    .perfect-centering {
                        position: absolute;
                        top: 50%;
                        left:50%;
                        transform: translate(-50%,-50%);
                      }
                      .text-white{
                          color:white;
                      }
                      `


                }
            </style>
            <div className="container">
                <div className="perfect-centering text-center">
                    <h1 className="display-1 ">
                        <i className={status === 'OK' ? "text-success far fa-check-circle" : "text-danger far fa-times-circle"}></i>
                    </h1>
                    <h4 className="text-white">
                        {status === 'OK' 
                            ? "Payment completed successful."
                            :  "Payment failed an error occured."
                 }
                    {error && <p>{error}</p>}
                    </h4>
                </div>
            </div>
        </>

    )
}

export default Response
