import React, { Component } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';
export default class Grid extends Component {
  render() {
    return (
      <div className='ag-theme-balham' style={{ width: 'auto', height: '300px' }}>
        <AgGridReact modules={AllCommunityModules} {...this.props}></AgGridReact>
      </div>
    );
  }
}
