import { parse, format } from 'date-fns';
import Moment from 'moment-timezone';
/**
 * coverts date from UTC to SQL date format
 * @param {date} dateTime
 * @returns date
 */
const dateToUtc = (dateTime) => {
  return parse(dateTime, 'dd/MM/yyyy HH:mm:ss', new Date()).toISOString();
};
/**
 * formats date to SQL date format
 * @param {date} date
 * @returns date
 */
const formatDate = (date) => {
  return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
};
/**
 * coverts time zone to EU zone
 * @param {date} date
 * @returns string
 */
const converDateToEuroZone = (date) => {
  const schedule_time = Moment(date);
  return schedule_time.tz('Europe/Paris').toString();
};
/**
 * calculates the SMS characters
 * @param {string} totalChars
 * @returns number
 */
const smsCount = (totalChars) => {
  if (totalChars.length <= 160) {
    return 1;
  } else if (totalChars.length <= 306) {
    return 2;
  } else if (totalChars.length <= 459) {
    return 3;
  } else if (totalChars.length <= 612) {
    return 4;
  } else if (totalChars.length <= 765) {
    return 5;
  } else if (totalChars.length <= 918) {
    return 6;
  } else if (totalChars.length <= 1000) {
    return 7;
  }
};

export { dateToUtc, formatDate, converDateToEuroZone, smsCount };
