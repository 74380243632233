import React, { useState,useRef,useEffect } from 'react'
import useForm from 'react-hook-form';
import axios from 'axios';
import { format } from 'date-fns';
import Alert from '../helpers/Alert';
import _ from 'lodash'
import {COMPANYID} from '../../config';
const CreateUser = () => {
    
    const userExist = useRef();
    const [formValues, setFormValues] = useState({});
    const [companyOptions, setCompanyOptions] = useState([]);
    const { register, errors, setValue, watch, handleSubmit } = useForm();

    useEffect(() => {
        companyList();
    },[])


    const handleControl = (formControl, value) => {
        setFormValues({ ...formValues, [formControl]: value });
        setValue(formControl, value);
    }

    const companyList = async () => {
        try {
            const token = localStorage.getItem('token');
            
            const { data } = await axios.get('/api/company/company-list', { headers: { authorization: token } });
            const options = [];
            if (_.isArray(data)) {
                data.map(({ company_id, company_name }) => options.push({ label: company_name, value: company_id }));
                setCompanyOptions(options);
            } else if (data === 'token_expired') {
                localStorage.setItem('token', 'token_expired');
            }
        } catch (err) {
            Alert('error', 'Error Occured !!', 'Error occured while inserting record.', 'fas fa-exclamation');
            console.log(err)
        }
    }
    

    const handleBlur = async () => {
        try {
            if (formValues.username !== ('' || undefined)) {
                const token = localStorage.getItem('token');
                const {data} = await axios.get(`/api/user/user-exists/${formValues['username']}`, { headers: { authorization: token } });
                userExist.current = data;
                
            }
        } catch (err) {
        Alert('success', 'Server Error', `Couldn't check users.`, 'fa-check-circle')
        }
    }

    const handleFormSubmit = async () => {
        try {
            const token = localStorage.getItem('token');
            if(formValues.role !== '1'){
                formValues['company_id'] = COMPANYID;
            }
            formValues['created_at'] = format(Date.now(), 'yyyy-MM-dd HH:mm:ss');
            const res = await axios.post('/api/user/create-user', formValues, { headers: { authorization: token } });
            if (res === 'token_expired') {
                localStorage.setItem('token', 'token_expired');
                Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
            } else {
                Alert('success', 'Operation Successfull', `User successfull created.`, 'fa-check-circle')
            }

        }
        catch (err) {
            Alert('error', 'Error Occured !!', err.response.data.message, 'fas fa-exclamation');
        }
    }

    return (

        <div className="container-fluid">
            <div className="row">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Create User</h4>
                        <hr />
                        <form onSubmit={handleSubmit(handleFormSubmit)}>
                            <div className="form-group">
                                <label className="mr-3">Role Type</label>
                                <select
                                    name="role"
                                    className="form-control  form-control-lg"
                                    onChange={e => handleControl('role', e.target.value)}
                                    ref={register({ name: 'role' }, { required: { value: true, message: 'Field is required.' } })}
                                >
                                    <option value="">Select type</option>
                                    <option value="0">Admin</option>
                                    <option value="1">Client</option>
                                </select>
                                <p className="error-text">{errors.role && errors.role.message}</p>

                                {
                                  formValues && formValues.role === '1' ?  <div className="form-group">
                                   
                                        <label className="mr-3">Company Name</label>
                                        <select className="form-control"
                                            name="company_id"
                                            onChange={e => handleControl('company_id', e.target.value)}
                                            value={formValues['company_id']}
                                            disabled={_.isEmpty(companyOptions)}
                                            ref={register({ name: 'company_id' }, { required: { value: true, message: 'Field is required.' } })}
                                        >
                                            <option>Select Company</option>
                                            {
                                                companyOptions.map(({ label, value }, index) => {
                                                    return <option key={index} value={value}>{label}</option>
                                                })
                                            }
                                        </select>
                                        <p className="error-text">{errors.company_id && errors.company_id.message}</p>
                                    
                                </div>
                                : null
                                }
                                <label className="mr-3">Username</label>
                                <input
                                    className="form-control  form-control-lg"
                                    name="username"
                                    onChange={e => handleControl('username', e.target.value)}
                                    onBlur={() => handleBlur()}
                                    type="text"
                                    value={formValues['username'] || ''}
                                    ref={register({ name: 'username' }, 
                                    { required: { value: true, message: 'Field is required.' },
                                      validate: { value: () => { return !userExist.current ? true : 'Username already exists.' } }
                                    })}
                                />
                                <p className="error-text">{errors.username && errors.username.message}</p>
                                <label className="mr-3">Password</label>
                                <input
                                    className="form-control  form-control-lg"
                                    autoComplete="new-password"
                                    name="password"
                                    onChange={e => handleControl('password', e.target.value)}
                                    type="password"
                                    value={formValues['password'] || ''}
                                    ref={register({ name: 'password' }, 
                                    { required: { value: true, message: 'Field is required.' }, 
                                      minLength: { value: 8, message: 'minimum length must be 8' }
                                    })

                                    }
                                />
                                <p className="error-text">{errors.password && errors.password.message}</p>
                                <label className="mr-3">Re-Password</label>
                                <input
                                    className="form-control  form-control-lg"
                                    name="repassword"
                                    onChange={e => handleControl('repassword', e.target.value)}
                                    type="password"
                                    value={formValues['repassword'] || ''}
                                    ref={
                                        register({ name: 'repassword' },
                                            {
                                                validate: { value: value => { return value === watch('password') ? true : 'Password not matched.' } },
                                                required: { value: true, message: 'Field is required.' },
                                                minLength: { value: 8, message: 'minimum length must be 8' }
                                            })}
                                />
                                <p className="error-text">{errors.repassword && errors.repassword.message}</p>
                            </div>
                            <button className="btn btn-primary float-right">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUser
