import React, { Component } from 'react';
import axios from 'axios';
import Alert from '../helpers/Alert';
import Grid from '../Grid';
import { format } from 'date-fns';
import { decrypt } from '../../Encrypter';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

/**
 * displays the list of companies
 */
export default class CompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null
    };

    this.role = decrypt(localStorage.getItem('role'));
    this.access_id = localStorage.getItem('access_id');
  }
  //
  // ─── GRID HEADER ────────────────────────────────────────────────────────────────
  //
  // columns to show into the grid
  columnDefs = [
    {
      headerName: null,
      width: 30,
      cellRendererFramework: (data) => {
        return (
          <a
            href='# '
            onClick={() => {
              this.props.history.push({
                pathname: '/edit-company',
                data
              });
            }}
          >
            <i className='fas fa-pencil-alt text-primary' title='Edit entry'></i>
          </a>
        );
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a
            href='# '
            onClick={() => {
              this.props.history.push({
                pathname: '/create-mail',
                state: data
              });
            }}
          >
            <i className='fas fa-at text-info' title='Add emails'></i>
          </a>
        );
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a
            href='# '
            onClick={() => {
              this.props.history.push({
                pathname: '/show-mail',
                state: data
              });
            }}
          >
            <i className='fas fa-list-alt text-info' title='Show emails'></i>
          </a>
        );
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a
            href='# '
            onClick={() => {
              this.props.history.push({
                pathname: '/create-mail-template',
                state: data
              });
            }}
          >
            <i className='fas fa-file-medical text-dark' title='add template'></i>
          </a>
        );
      }
    },
    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return (
          <a
            href='# '
            onClick={() => {
              this.props.history.push({
                pathname: '/mail-template-list',
                state: data
              });
            }}
          >
            <i className='fas fa-list-alt text-dark' title='edit template'></i>
          </a>
        );
      }
    },

    { headerName: 'Company ID', field: 'company_id' },
    { headerName: 'Company Name', field: 'company_name' },
    { headerName: 'Address', field: 'address' },
    { headerName: 'Zip Code', field: 'zip_code' },
    { headerName: 'City', field: 'city' },
    { headerName: 'Country', field: 'country' },
    { headerName: 'Email', field: 'email' },
    {
      headerName: 'Created Date',
      field: 'created_at',
      cellRendererFramework: ({ value }) => {
        return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
      }
    },
    {
      headerName: 'Updated Date',
      field: 'updated_at',
      cellRendererFramework: ({ value }) => {
        return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
      }
    },

    {
      headerName: null,
      width: 30,
      cellRendererFramework: ({ data }) => {
        return this.role === 0 ? (
          <a href='# ' onClick={() => this.confirmDrop(data.company_id)}>
            <i className='far fa-trash-alt text-danger' title='Delete entry'></i>
          </a>
        ) : (
          <></>
        );
      }
    }
  ];
  //gets the company data onload
  componentDidMount() {
    this.getCompanyData();
  }

  /**
   * fetches company data
   */
  getCompanyData = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } =
        this.role === '0'
          ? await axios.get('/api/company', { headers: { authorization: token } })
          : await axios.get(`/api/company/company-role/${this.access_id}`, { headers: { authorization: token } });

      if (data === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
      } else {
        this.setState({ data });
      }
    } catch (err) {
      const { data } = err.response;
      console.log(data);
    }
  };
  /**
   * confirmation box to removes the company
   * @param  {number|string} recordID
   */
  confirmDrop = (recordID) => {
    confirmAlert({
      title: 'Remove Record',
      message: 'Are you sure to remove this record.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.dropRecord(recordID)
        },
        {
          label: 'No'
        }
      ]
    });
  };

  /**
   * removes the company record
   * @param  {number|string} recordID
   */
  dropRecord = async (recordID) => {
    try {
      const token = localStorage.getItem('token');
      const res = await axios.delete(`/api/company/remove-company/${recordID}`, {
        headers: { 'Content-Type': 'application/json', authorization: token }
      });

      if (res === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
        Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
      } else {
        Alert('success', 'success', 'Record Deleted successfully', 'fa-check-circle');
        this.getCompanyData();
      }
    } catch (err) {
      Alert('error', 'Error', 'An error occured while deleting record.', 'fa-exclamation');
      console.log(err);
    }
  };

  render() {
    return (
      <>
        <div className='container-fluid'>
          <div className='row'>
            <div className='card'>
              <div className='card-body'>
                <h4 className='card-title'>Company List</h4>
                <Grid columnDefs={this.columnDefs} rowData={this.state.data} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
