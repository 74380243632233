import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Alert from '../helpers/Alert';
import { format } from 'date-fns';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../helpers/utils';
import useForm from 'react-hook-form';
import 'react-autocomplete-input/dist/bundle.css';
import CampaignBase from './CampaignBase';
import MessageBase from './MessageBase';
import MailBase from './MailBase';
/**
 * Core component, It creates campaigns and
 * renders different campaigns together
 * into one component.
 */
const CreateCampagin = () => {
  //simple state to save values of form
  const [formValues, setFormValues] = useState({
    mode: 0,
    type: 0,
    expiry_mode: 0,
    duration: 72,
    max_tries: 0
  });

  //sets company list options htmlFor select
  const [companyOptions, setCompanyOptions] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [aliasOptions, setAliasOptions] = useState([]);
  const [providerData, setProviderData] = useState([]);
  const [mailTemplateOptions, setMailTemplateOptions] = useState([]);
  const [showExpiry, setShowExpiry] = useState(true);
  const [mode, setMode] = useState({ realtime: true, batch: false });
  const [type, setType] = useState({
    visual: true,
    simple: false,
    template: false
  });
  const [expiryMode, setExpiryMode] = useState({
    visited: true,
    tries: false,
    noexpiry: false
  });
  const { register, errors, setValue, unregister, handleSubmit } = useForm({
    defaultValues: { duration: 72, max_tries: 0 }
  });
  const [campaignType, setCampaignType] = useState(true);
  const templateOptions = [
    'CONTACTNAME',
    'MOBILENUMBER',
    'EMAIL',
    'AMOUNT',
    'HOMECONTACTNUMBER',
    'DESTINATIONSTREET1',
    'DESTINATIONSTREET2',
    'DESTINATIONSTREET3',
    'DESTINATIONCITY',
    'DESTINATIONPROVINCE',
    'DESTINATIONPOSTCODE',
    'DESTINATIONCOUNTRY',
    'BILLINGSTREET1',
    'BILLINGSTREET2',
    'BILLINGSTREET3',
    'BILLINGCITY',
    'BILLINGPROVINCE',
    'BILLINGPOSTCODE',
    'BILLINGCOUNTRY',
    'URL'
  ];

  // mapped fields from contact table

  const templateValue = {
    CONTACTNAME: '{contact_name}',
    MOBILENUMBER: '{mobile_number}',
    EMAIL: '{data1}',
    AMOUNT: '{data2}',
    HOMECONTACTNUMBER: '{data3}',
    DESTINATIONSTREET1: '{data4}',
    DESTINATIONSTREET2: '{data5}',
    DESTINATIONSTREET3: '{data6}',
    DESTINATIONCITY: '{data7}',
    DESTINATIONPROVINCE: '{data8}',
    DESTINATIONPOSTCODE: '{data9}',
    DESTINATIONCOUNTRY: '{data10}',
    BILLINGSTREET1: '{data11}',
    BILLINGSTREET2: '{data12}',
    BILLINGSTREET3: '{data13}',
    BILLINGCITY: '{data14}',
    BILLINGPROVINCE: '{data15}',
    BILLINGPOSTCODE: '{data16}',
    BILLINGCOUNTRY: '{data17}',
    URL: '{url}'
  };

  const isEdit = useRef(false);

  //
  // ──────────────────────────────────────────────────────────── I ──────────
  //   :::::: U S E E F F E C T S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────
  //

  // gets company and provider list

  useEffect(() => {
    companyList();
    messageProviderList();
    // eslint-disable-next-line
  }, []);

  // track changes of the given dependencies
  useEffect(() => {
    if (formValues.provider_id || formValues.mail_provider_id) {
      if (campaignType) {
        getMessageAliasList();
      } else {
        getMailAliasList();
        getTemplateList();
      }
    }

    if (campaignType) {
      unregister('mail_provider_id');
    } else {
      unregister('provider_id');
      unregister('sms_head');
      unregister('sms_tail');
      unregister('sms_text');
      unregister('template');
    }
    return () => {
      setAliasOptions([]);
    };
    // eslint-disable-next-line
  }, [
    formValues.provider_id,
    campaignType,
    formValues.company_id,
    formValues.mail_provider_id,
  ]);
  // track if any changes made to campaign_type
  useEffect(() => {
    const cType = parseInt(formValues['campaign_type']);
    if (cType === 0 || cType === 1) {
      setCampaignType(true, messageProviderList());
    } else if (cType === 2 || cType === 3) {
      setCampaignType(false, mailProviderList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.campaign_type]);
  useEffect(() => {
    const el = document.getElementsByClassName('react-autocomplete-input');
    if (!_.isEmpty(el[0])) {
      el[0].setAttribute('style', 'top:-300px;left:32px');
    }
    // eslint-disable-next-line
  }, [formValues.template]);
  // track changes to mode
  useEffect(() => {
    if (formValues.mode === '0') {
      unregister('schedule_time');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.mode]);

  // ────────────────────────────────────────────────────────────────────────────────
  // eslint-disable-next-line react-hooks/exhaustive-deps
  /**
   * generic event handler for inputs
   * @param  {string} formControl
   * @param  {any} value
   */
  const handleControl = (formControl, value) => {
    setFormValues({ ...formValues, [formControl]: value });
    setValue(formControl, value);
  };
  /**
   * generic event handler for radio
   * @param  {string} formControl
   * @param  {any} value
   */
  const handleRadio = (formControl, value) => {
    if (formControl === 'noexpiry') {
      setFormValues({ ...formValues, expiry_mode: value });
      setExpiryMode({ visited: false, tries: false, noexpiry: true });
    } else if (formControl === 'visited') {
      setFormValues({ ...formValues, expiry_mode: value });
      setExpiryMode({ visited: true, tries: false, noexpiry: false });
    } else if (formControl === 'tries') {
      setFormValues({ ...formValues, expiry_mode: value });
      setExpiryMode({ visited: false, tries: true, noexpiry: false });
    } else if (formControl === 'visual') {
      setType({ visual: true, simple: false });
      delete formValues['sms_text'];
      delete formValues['template'];
      unregister('sms_text');
      unregister('template');
      setShowExpiry(true);
      handleControl('type', value);
    } else if (formControl === 'simple' || formControl === 'template') {
      const switcher = formControl === 'simple' ? true : false;
      setType({ visual: false, simple: switcher, template: !switcher });

      if (!switcher) {
        unregister('sms_text');
        delete formValues['sms_text'];
      } else {
        delete formValues['template'];
        unregister('template');
      }

      delete formValues['sms_head'];
      delete formValues['sms_tail'];
      delete formValues['sms_url'];
      unregister('sms_head');
      unregister('sms_tail');
      unregister('sms_url');
      setShowExpiry(!switcher);
      handleControl('type', value);
    }
  };
  /**
   * gets the company list
   */
  const companyList = async () => {
    try {
      const token = localStorage.getItem('token');
      const options = [];
      const { data } = await axios.get('/api/company/company-list', {
        headers: { authorization: token }
      });

      if (_.isArray(data)) {
        data.map(({ company_id, company_name }) => options.push({ label: company_name, value: company_id }));
        setCompanyOptions(options);
      } else if (data === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
      }
    } catch (err) {
      Alert('error', 'Error Occured !!', 'Error occured while getting record.', 'fas fa-exclamation');
      console.log(err);
    }
  };
  /**
   * gets the providers list
   */
  const messageProviderList = async () => {
    try {
      let options = [];
      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/provider/provider-list/', {
        headers: { authorization: token }
      });

      if (_.isArray(data)) {
        options = [];
        data.map(({ provider_id, provider_name }) => options.push({ label: provider_name, value: provider_id }));
      } else if (data === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
      }

      setProviderData(data);
      setProviderOptions(options);
    } catch (err) {
      Alert('error', 'Error Occured !!', 'Error occured while loading provider list. Please refresh page.', 'fas fa-exclamation');
      console.log(err);
    }
  };
  /** gets the mail providers list
   */
  const mailProviderList = async () => {
    try {
      let options = [];

      const token = localStorage.getItem('token');
      const { data } = await axios.get('/api/mailprovider/provider-list/', {
        headers: { authorization: token }
      });

      if (_.isArray(data)) {
        data.map(({ mail_provider_id, mail_provider_name }) => options.push({ label: mail_provider_name, value: mail_provider_id }));
      } else if (data === 'token_expired') {
        localStorage.setItem('token', 'token_expired');
      }

      setProviderOptions(() => options);
    } catch (err) {
      Alert('error', 'Error Occured !!', 'Error occured while loading provider list. Please refresh page.', 'fas fa-exclamation');
      console.log(err);
    }
  };
  /**
   * gets the message sender list
   */
  const getMessageAliasList = async () => {
    try {
      const token = localStorage.getItem('token');
      const providerType = providerData.filter((o) => o.provider_id.toString() === formValues.provider_id)[0];

      if (providerType.provider_type_id === 1) {
        const { data } = await axios.get(`/api/provider/get-alias/${formValues.provider_id}`, { headers: { authorization: token } });
        const aliasJSON = data;
        const aliasArray = [];
        // eslint-disable-next-line
        aliasJSON["alias"].map(({ alias }) => {
          if (/[a-zA-Z]/.test(alias)) {
            aliasArray.push(alias);
          }
        });
        setAliasOptions(aliasArray);
      } else if (providerType.provider_type_id === 2 || 4) {
        const { data } = await axios.get(`/api/provider/get-alias-general/${formValues.provider_id}`, {
          headers: { authorization: token }
        });
        const aliasArray = [];
        // eslint-disable-next-line
        data.map(({ alias }) => {
          aliasArray.push(alias);
        });
        setAliasOptions(aliasArray);
      } else if (providerType.provider_type_id === 3) {
        //twilio
      }
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Error Occured !!', data, 'fas fa-exclamation');
    }
  };
  /**
   * gets the email sender list
   */
  const getMailAliasList = async () => {
    try {
      const token = localStorage.getItem('token');
      const { company_id } = formValues;
      if (company_id) {
        const emails = [];
        const { data } = await axios.get(`/api/mailprovider/get-alias/${company_id}`, {
          headers: { authorization: token }
        });
        data.map(({ email }) => emails.push(email));
        setAliasOptions(emails);
      } else {
        Alert('error', 'Error Occured !!', 'Please select company.', 'fas fa-exclamation');
      }
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Error Occured !!', data, 'fas fa-exclamation');
    }
  };
  /**
   * handles form submit
   */
  const handleFormSubmit = async () => {
    try {
      //delete data['batchfiles'];
      const token = localStorage.getItem('token');
      const formData = { ...formValues };

      delete formData.template_html;
      delete formData.template_name;

      if (campaignType) {
        if (formData.type === '2') {
          let templateString = formData['template'];
          const templateVars = formData['template'].match(/@[\w]+/gm);
          if (templateVars.length > 0) {
            // eslint-disable-next-line
            templateVars.map((val) => {
              const key = val.replace('@', '');
              templateString = templateString.replace(val, templateValue[key]);
            });

            formData['sms_text'] = templateString;
            delete formData['template'];
          }
        }
        if (formData['schedule_time']) {
          formData['schedule_time'] = formatDate(formData['schedule_time']);
        }

        if (isEdit.current) {
          formData['updated_at'] = format(Date.now(), 'yyyy-MM-dd HH:mm:ss');
          delete formData['company_name'];
          delete formData['provider_name'];
          await axios.post(
            '/api/campaign/update-campaign',
            { data: formData },
            {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );
        } else {
          formData.status = 0;
          formData.created_at = format(Date.now(), 'yyyy-MM-dd HH:mm:ss');
          if (formData['sms_url']) {
            formData['sms_url'] = formData['sms_url'].replace(/\/?$/, '/');
          }
          const res = await axios.post(
            '/api/campaign/add-campaign',
            { data: formData },
            {
              headers: {
                'Content-Type': 'application/json',
                authorization: token
              }
            }
          );
          if (res === 'token_expired') {
            localStorage.setItem('token', 'token_expired');
            Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
          } else {
            Alert('success', 'success', 'Campaign created successfully', 'fa-check-circle');
          }
        }
      } else {
        formData.created_at = format(Date.now(), 'yyyy-MM-dd HH:mm:ss');
        formData.schedule_time = formData.schedule_time && formatDate(formData.schedule_time);
        formData.sms_url = formData.sms_url.replace(/\/?$/, '/');
        delete formData.type;
        const res = await axios.post(
          '/api/campaign/add-campaign',
          { data: formData },
          {
            headers: {
              'Content-Type': 'application/json',
              authorization: token
            }
          }
        );
        if (res === 'token_expired') {
          localStorage.setItem('token', 'token_expired');
          Alert('error', 'Session expired', 'Session expired.', 'fa-exclamation');
        } else {
          Alert('success', 'success', 'Campaign created successfully', 'fa-check-circle');
        }
      }
    } catch (err) {
      Alert('error', 'Error Occured !!', 'Error occured while inserting record.', 'fas fa-exclamation');
      console.log(err);
    }
  };
  /**
   * gets the list of saved mail templates
   */
  const getTemplateList = async () => {
    try {
      const token = localStorage.getItem('token');
      const { company_id } = formValues;
      if (company_id) {
        const mailTemplateOptions = [];
        const { data } = await axios.get(`/api/mailtemplate/get-template/${company_id}`, {
          headers: { authorization: token }
        });
        data.map(({ mail_template_id, template_name }) =>
          mailTemplateOptions.push({
            label: template_name,
            value: mail_template_id
          })
        );
        setMailTemplateOptions(mailTemplateOptions);
      } else {
        Alert('error', 'Error Occured !!', 'Please select company.', 'fas fa-exclamation');
      }
    } catch (err) {
      const { data } = err.response;
      Alert('error', 'Error Occured !!', data, 'fas fa-exclamation');
    }
  };

  /* Renders SMS or Mail part of the campaign */
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>Create Campaign</h4>
              <hr />
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <CampaignBase
                  handleControl={handleControl}
                  formValues={formValues}
                  companyOptions={companyOptions}
                  register={register}
                  errors={errors}
                />
                {campaignType ? (
                  <MessageBase
                    handleControl={handleControl}
                    formValues={formValues}
                    register={register}
                    errors={errors}
                    aliasOptions={aliasOptions}
                    mode={mode}
                    setMode={setMode}
                    showExpiry={showExpiry}
                    expiryMode={expiryMode}
                    handleRadio={handleRadio}
                    type={type}
                    templateOptions={templateOptions}
                    providerOptions={providerOptions}
                  />
                ) : (
                  <MailBase
                    handleControl={handleControl}
                    formValues={formValues}
                    register={register}
                    errors={errors}
                    providerOptions={providerOptions}
                    mode={mode}
                    setMode={setMode}
                    showExpiry={showExpiry}
                    expiryMode={expiryMode}
                    handleRadio={handleRadio}
                    aliasOptions={aliasOptions}
                    mailTemplateOptions={mailTemplateOptions}
                  />
                )}
                <button type='submit' className='btn btn-primary float-right'>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCampagin;
