import React from 'react';
/**
 * renders header
 * @returns JSX
 */
const Header = () => {
  const logout = () => {
    // on log out clears the localstorage
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('access_id');
  };

  return (
    <header className='topbar' data-navbarbg='skin5'>
      <nav className='navbar top-navbar navbar-expand-md navbar-dark'>
        <div className='navbar-header expand-logo' data-logobg='skin5'>
          {/*eslint-disable-next-line*/}
          <a className="nav-toggler waves-effect waves-light d-block d-md-none" href="#"><i className="ti-menu ti-close"></i></a>
          <a className='navbar-brand' href='/status'>
            <b className='logo-icon p-l-10'>
              <img src='assets/images/logo-icon.png' alt='homepage' className='light-logo' />
            </b>
            <span className='logo-text'>
              <img src='assets/images/sinergidea.png' alt='homepage' className='light-logo' width='200' />
            </span>
          </a>

          {/*eslint-disable-next-line*/}
                    <a className="topbartoggler d-block d-md-none waves-effect waves-light" href="#" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><i className="ti-more"></i></a>
        </div>
        <div className='navbar-collapse collapse' id='navbarSupportedContent' data-navbarbg='skin5'>
          <ul className='navbar-nav float-left mr-auto'>
            {/*eslint-disable-next-line*/}
                        <li className="nav-item d-none d-md-block">
              <a className='nav-link sidebartoggler waves-effect waves-light' href='#' data-sidebartype='mini-sidebar'>
                <i className='mdi mdi-menu font-24'></i>
              </a>
            </li>
          </ul>
        </div>
        <ul className='navbar-nav float-right'>
          <li className='nav-item dropdown'>
            {/*eslint-disable-next-line*/}
                <a className="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              >
              <img src='assets/images/users/1.jpg' alt='user' className='rounded-circle' width='31' />
            </a>
            <div className='dropdown-menu dropdown-menu-right user-dd animated'>
              <a className='dropdown-item' href='/login' onClick={() => logout()}>
                <i className='fa fa-power-off m-r-5 m-l-5'></i> Logout
              </a>
              <div className='dropdown-divider'></div>
            </div>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
