import React from 'react';
import Login from './components/Login';
import { BrowserRouter, Switch, Route } from 'react-browser-router';
import Reporting from './components/Reporting';
import CompanyList from './components/company/CompanyList';
import CampaignList from './components/campaign/CampaignList';
import CreateCompany from './components/company/CreateCompany';
import CreateCampagin from './components/campaign/CreateCampagin';
import BatchFile from './components/batchfiles/BatchFile';
import CreateUser from './components/users/CreateUser';
import axios from 'axios';
import PrivateRoute from './PrivateRoute';
import Response from './components/templates/payment/Response';
import SMSReport from './components/reporting/SMSReport';
import PaymentReport from './components/reporting/PaymentReport';
import Providers from './components/providers/Providers';
import CreateProviders from './components/providers/CreateProviders';
import UpdateProviders from './components/providers/UpdateProvider';
import CreateAlias from './components/providers/CreateAlias';
import NotFound from './components/ErrorPages/NotFound';
import ShowAlias from './components/providers/ShowAlias';
import ApiCampaginList from './components/campaign/ApiCampaginList';
import ShowUser from './components/users/ShowUser';
import UpdateUser from './components/users/UpdateUser';
import CreatePaymentConfig from './components/company/CreatePaymentConfig';
import ShowPaymentConfig from './components/company/ShowPaymentConfig';
import UpdatePaymentConfig from './components/company/UpdatePaymentConfig';
import CreateMailProviders from './components/mail-providers/CreateMailProviders';
import MailProviders from './components/mail-providers/MailProviders';
import UpdateMailProviders from './components/mail-providers/UpdateMailProviders';
import CreateEmail from './components/company/CreateEmail';
import EmailList from './components/company/EmailList';
import UpdateEmail from './components/company/UpdateEmail';
import CreateMailTemplate from './components/company/CreateMailTemplate';
import MailTemplateList from './components/company/MailTemplateList';
import UpdateMailTemplate from './components/company/UpdateMailTemplate';
import Status from './components/Status';
/**
 * isAuthticated checks on each call if
 * user is authenticated.
 */
const isAuthticated = () => {
  const token = localStorage.getItem('token');
  if (token === 'token_expired') {
    return false;
  } else if (token) {
    return axios
      .get('/api/user/authorized', { headers: { authorization: token } })
      .then(({ data }) => {
        if (data.isAuth) {
          return true;
        }
      })
      .catch((err) => {
        const { data } = err.response;
        localStorage.setItem('token', data);
        window.location = '/login';
        return false;
      });
  }
};
/**
 * all project routes are defined in this file
 * when you want to render a new component. It
 * is important you register that component in
 * this file.
 * @param {any} props
 * @returns JSX
 */
const Routes = (props) => {
  const authenticated = isAuthticated();
  const role = 0; //parseInt(decrypt(localStorage.getItem('role') || '0')) ;
  console.log('Routes -> role', role);

  return (
    <BrowserRouter>
      {/* Role is not used anymore it was created as dashboard for client later split the logic into clients own separate project */}
      {role === 1 ? (
        <Switch>
          <Route exact path='/' render={(props) => <Login {...props} authenticated={authenticated} />} />
          <Route exact path='/login' render={(props) => <Login {...props} authenticated={authenticated} />} />
          <PrivateRoute authenticated={authenticated} exact path='/company-list' component={CompanyList} />
          <PrivateRoute authenticated={authenticated} exact path='/reporting' component={Reporting} />
          <PrivateRoute authenticated={authenticated} exact path='/sms-reporting' component={SMSReport} />
          <PrivateRoute authenticated={authenticated} exact path='/payment-report' component={PaymentReport} />
          <Route component={NotFound} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path='/' render={(props) => <Login {...props} authenticated={authenticated} />} />
          <Route exact path='/login' render={(props) => <Login {...props} authenticated={authenticated} />} />
          <PrivateRoute authenticated={authenticated} exact path='/status' component={Status} />
          <PrivateRoute authenticated={authenticated} exact path='/company-list' component={CompanyList} />
          <PrivateRoute authenticated={authenticated} exact path='/batch-file' component={BatchFile} />
          <PrivateRoute authenticated={authenticated} exact path='/edit-campaign' component={CreateCampagin} />
          <PrivateRoute authenticated={authenticated} exact path='/create-campaign' component={CreateCampagin} />
          <PrivateRoute authenticated={authenticated} exact path='/edit-company' component={CreateCompany} />
          <PrivateRoute authenticated={authenticated} exact path='/create-user' component={CreateUser} />
          <PrivateRoute authenticated={authenticated} exact path='/create-company' component={CreateCompany} />
          <PrivateRoute authenticated={authenticated} exact path='/campaign-list' component={CampaignList} />
          <PrivateRoute authenticated={authenticated} exact path='/reporting' component={Reporting} />
          <PrivateRoute authenticated={authenticated} exact path='/sms-reporting' component={SMSReport} />
          <PrivateRoute authenticated={authenticated} exact path='/payment-report' component={PaymentReport} />
          <PrivateRoute authenticated={authenticated} exact path='/providers' component={Providers} />
          <PrivateRoute authenticated={authenticated} exact path='/create-providers' component={CreateProviders} />
          <PrivateRoute authenticated={authenticated} exact path='/update-providers' component={UpdateProviders} />
          <PrivateRoute authenticated={authenticated} exact path='/create-alias' component={CreateAlias} />
          <PrivateRoute authenticated={authenticated} exact path='/show-alias' component={ShowAlias} />
          <PrivateRoute authenticated={authenticated} exact path='/campaign-apis-list' component={ApiCampaginList} />
          <PrivateRoute authenticated={authenticated} exact path='/show-user' component={ShowUser} />
          <PrivateRoute authenticated={authenticated} exact path='/update-user' component={UpdateUser} />
          <PrivateRoute authenticated={authenticated} exact path='/create-payment-config' component={CreatePaymentConfig} />
          <PrivateRoute authenticated={authenticated} exact path='/payment-config-list' component={ShowPaymentConfig} />
          <PrivateRoute authenticated={authenticated} exact path='/update-payment-config-list' component={UpdatePaymentConfig} />
          <PrivateRoute authenticated={authenticated} exact path='/create-mail-providers' component={CreateMailProviders} />
          <PrivateRoute authenticated={authenticated} exact path='/mail-providers' component={MailProviders} />
          <PrivateRoute authenticated={authenticated} exact path='/update-mail-providers' component={UpdateMailProviders} />
          <PrivateRoute authenticated={authenticated} exact path='/create-mail' component={CreateEmail} />
          <PrivateRoute authenticated={authenticated} exact path='/show-mail' component={EmailList} />
          <PrivateRoute authenticated={authenticated} exact path='/update-mail' component={UpdateEmail} />
          <PrivateRoute authenticated={authenticated} exact path='/create-mail-template' component={CreateMailTemplate} />
          <PrivateRoute authenticated={authenticated} exact path='/mail-template-list' component={MailTemplateList} />
          <PrivateRoute authenticated={authenticated} exact path='/update-mail-template' component={UpdateMailTemplate} />
          <Route exact path='/response' component={Response} />
          <Route component={NotFound} />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default Routes;
